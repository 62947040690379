// import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";


export default function ScheduleACall() {
  return <>
  <Helmet>
  <title>Schedule a Call to Explore Exciting Opportunities at Sourcedesk</title>
<meta name="description" content="Seize your career's next chapter! Schedule a call with Sourcedesk to explore compelling opportunities. Elevate your success story with confidence. Connect now!" />

    {/* <!-- Head  -->
    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 

  </Helmet>
    <section class="full_width no_banner_sec schedule_step_one border_top fix_padding">
        <div class="container">
            <div class="text_controller text_controller_center">
                <h1>Proceed to Access <span> Unmatched Expertise </span></h1>
                <hr />
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="step_one_content">
                        <div class="text_controller">
                            <h4>STEP 1 OF 2</h4>
                            <h2><b>Let's find your ideal</b> <br /><span>developer</span></h2> <img src="images/schedule_cnt_arrow.png" alt="schedule_cnt_arrow" className="d-none d-lg-block" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="step_one_form">
                    <div class="row">
                    
                        <form action="https://crm.zoho.com/crm/WebToLeadForm" method="POST" id="webform6057464000001072101" name="WebToLeads6057464000001072101">

                        <input type="text" style={{display: "none"}} name="xnQsjsdp" value="2e0f241de16d611e370174a915dcc4b87b8186cb6d0968de894d55859e9085cd"></input>
                        <input type="hidden" name="zc_gad" id="zc_gad" value=""></input>
                        <input type="text" style={{display: "none"}} name="xmIwtLD" value="f1335e5aa8657be4053d420e0cc330f934b9d254f1caac2a70d6bce02dd77bfa0b17380b85f4a2d0225987224ca4406f"></input>
                        <input type="text" style={{display: "none"}} name="actionType" value="TGVhZHM="></input>
                        <input type="text" style={{display: "none"}} name="returnURL" value="https://www.sourcedesk.io/book-an-event"></input>
                        

                            <div class="col-md-12"> <label>Phone Number</label>
                                <div class="row">
                                    <div class="col-4 col-md-4 col-sm-3 col-lg-3 col-xl-3"> <select name="LEADCF15" id="LEADCF15" class="country_number" required>
                                            <option value="+1"> US +1 </option>
                                            <option value="+93"> AF +93 </option>
                                            <option value="+355"> AL +355 </option>
                                            <option value="+213"> DZ +213 </option>
                                            <option value="+1684"> AS +1684 </option>
                                            <option value="+376"> AD +376 </option>
                                            <option value="+244"> AO +244 </option>
                                            <option value="+1264"> AI +1264 </option>
                                            <option value="+0"> AQ +0 </option>
                                            <option value="+1268"> AG +1268 </option>
                                            <option value="+54"> AR +54 </option>
                                            <option value="+374"> AM +374 </option>
                                            <option value="+297"> AW +297 </option>
                                            <option value="+61"> AU +61 </option>
                                            <option value="+43"> AT +43 </option>
                                            <option value="+994"> AZ +994 </option>
                                            <option value="+1242"> BS +1242 </option>
                                            <option value="+973"> BH +973 </option>
                                            <option value="+880"> BD +880 </option>
                                            <option value="+1246"> BB +1246 </option>
                                            <option value="+375"> BY +375 </option>
                                            <option value="+32"> BE +32 </option>
                                            <option value="+501"> BZ +501 </option>
                                            <option value="+229"> BJ +229 </option>
                                            <option value="+1441"> BM +1441 </option>
                                            <option value="+975"> BT +975 </option>
                                            <option value="+591"> BO +591 </option>
                                            <option value="+387"> BA +387 </option>
                                            <option value="+267"> BW +267 </option>
                                            <option value="+0"> BV +0 </option>
                                            <option value="+55"> BR +55 </option>
                                            <option value="+246"> IO +246 </option>
                                            <option value="+673"> BN +673 </option>
                                            <option value="+359"> BG +359 </option>
                                            <option value="+226"> BF +226 </option>
                                            <option value="+257"> BI +257 </option>
                                            <option value="+855"> KH +855 </option>
                                            <option value="+237"> CM +237 </option>
                                            <option value="+1"> CA +1 </option>
                                            <option value="+238"> CV +238 </option>
                                            <option value="+1345"> KY +1345 </option>
                                            <option value="+236"> CF +236 </option>
                                            <option value="+235"> TD +235 </option>
                                            <option value="+56"> CL +56 </option>
                                            <option value="+86"> CN +86 </option>
                                            <option value="+61"> CX +61 </option>
                                            <option value="+672"> CC +672 </option>
                                            <option value="+57"> CO +57 </option>
                                            <option value="+269"> KM +269 </option>
                                            <option value="+242"> CG +242 </option>
                                            <option value="+242"> CD +242 </option>
                                            <option value="+682"> CK +682 </option>
                                            <option value="+506"> CR +506 </option>
                                            <option value="+225"> CI +225 </option>
                                            <option value="+385"> HR +385 </option>
                                            <option value="+53"> CU +53 </option>
                                            <option value="+357"> CY +357 </option>
                                            <option value="+420"> CZ +420 </option>
                                            <option value="+45"> DK +45 </option>
                                            <option value="+253"> DJ +253 </option>
                                            <option value="+1767"> DM +1767 </option>
                                            <option value="+1809"> DO +1809 </option>
                                            <option value="+593"> EC +593 </option>
                                            <option value="+20"> EG +20 </option>
                                            <option value="+503"> SV +503 </option>
                                            <option value="+240"> GQ +240 </option>
                                            <option value="+291"> ER +291 </option>
                                            <option value="+372"> EE +372 </option>
                                            <option value="+251"> ET +251 </option>
                                            <option value="+500"> FK +500 </option>
                                            <option value="+298"> FO +298 </option>
                                            <option value="+679"> FJ +679 </option>
                                            <option value="+358"> FI +358 </option>
                                            <option value="+33"> FR +33 </option>
                                            <option value="+594"> GF +594 </option>
                                            <option value="+689"> PF +689 </option>
                                            <option value="+0"> TF +0 </option>
                                            <option value="+241"> GA +241 </option>
                                            <option value="+220"> GM +220 </option>
                                            <option value="+995"> GE +995 </option>
                                            <option value="+49"> DE +49 </option>
                                            <option value="+233"> GH +233 </option>
                                            <option value="+350"> GI +350 </option>
                                            <option value="+30"> GR +30 </option>
                                            <option value="+299"> GL +299 </option>
                                            <option value="+1473"> GD +1473 </option>
                                            <option value="+590"> GP +590 </option>
                                            <option value="+1671"> GU +1671 </option>
                                            <option value="+502"> GT +502 </option>
                                            <option value="+224"> GN +224 </option>
                                            <option value="+245"> GW +245 </option>
                                            <option value="+592"> GY +592 </option>
                                            <option value="+509"> HT +509 </option>
                                            <option value="+0"> HM +0 </option>
                                            <option value="+39"> VA +39 </option>
                                            <option value="+504"> HN +504 </option>
                                            <option value="+852"> HK +852 </option>
                                            <option value="+36"> HU +36 </option>
                                            <option value="+354"> IS +354 </option>
                                            <option value="+91"> IN +91 </option>
                                            <option value="+62"> ID +62 </option>
                                            <option value="+98"> IR +98 </option>
                                            <option value="+964"> IQ +964 </option>
                                            <option value="+353"> IE +353 </option>
                                            <option value="+972"> IL +972 </option>
                                            <option value="+39"> IT +39 </option>
                                            <option value="+1876"> JM +1876 </option>
                                            <option value="+81"> JP +81 </option>
                                            <option value="+962"> JO +962 </option>
                                            <option value="+7"> KZ +7 </option>
                                            <option value="+254"> KE +254 </option>
                                            <option value="+686"> KI +686 </option>
                                            <option value="+850"> KP +850 </option>
                                            <option value="+82"> KR +82 </option>
                                            <option value="+965"> KW +965 </option>
                                            <option value="+996"> KG +996 </option>
                                            <option value="+856"> LA +856 </option>
                                            <option value="+371"> LV +371 </option>
                                            <option value="+961"> LB +961 </option>
                                            <option value="+266"> LS +266 </option>
                                            <option value="+231"> LR +231 </option>
                                            <option value="+218"> LY +218 </option>
                                            <option value="+423"> LI +423 </option>
                                            <option value="+370"> LT +370 </option>
                                            <option value="+352"> LU +352 </option>
                                            <option value="+853"> MO +853 </option>
                                            <option value="+389"> MK +389 </option>
                                            <option value="+261"> MG +261 </option>
                                            <option value="+265"> MW +265 </option>
                                            <option value="+60"> MY +60 </option>
                                            <option value="+960"> MV +960 </option>
                                            <option value="+223"> ML +223 </option>
                                            <option value="+356"> MT +356 </option>
                                            <option value="+692"> MH +692 </option>
                                            <option value="+596"> MQ +596 </option>
                                            <option value="+222"> MR +222 </option>
                                            <option value="+230"> MU +230 </option>
                                            <option value="+269"> YT +269 </option>
                                            <option value="+52"> MX +52 </option>
                                            <option value="+691"> FM +691 </option>
                                            <option value="+373"> MD +373 </option>
                                            <option value="+377"> MC +377 </option>
                                            <option value="+976"> MN +976 </option>
                                            <option value="+1664"> MS +1664 </option>
                                            <option value="+212"> MA +212 </option>
                                            <option value="+258"> MZ +258 </option>
                                            <option value="+95"> MM +95 </option>
                                            <option value="+264"> NA +264 </option>
                                            <option value="+674"> NR +674 </option>
                                            <option value="+977"> NP +977 </option>
                                            <option value="+31"> NL +31 </option>
                                            <option value="+599"> AN +599 </option>
                                            <option value="+687"> NC +687 </option>
                                            <option value="+64"> NZ +64 </option>
                                            <option value="+505"> NI +505 </option>
                                            <option value="+227"> NE +227 </option>
                                            <option value="+234"> NG +234 </option>
                                            <option value="+683"> NU +683 </option>
                                            <option value="+672"> NF +672 </option>
                                            <option value="+1670"> MP +1670 </option>
                                            <option value="+47"> NO +47 </option>
                                            <option value="+968"> OM +968 </option>
                                            <option value="+92"> PK +92 </option>
                                            <option value="+680"> PW +680 </option>
                                            <option value="+970"> PS +970 </option>
                                            <option value="+507"> PA +507 </option>
                                            <option value="+675"> PG +675 </option>
                                            <option value="+595"> PY +595 </option>
                                            <option value="+51"> PE +51 </option>
                                            <option value="+63"> PH +63 </option>
                                            <option value="+0"> PN +0 </option>
                                            <option value="+48"> PL +48 </option>
                                            <option value="+351"> PT +351 </option>
                                            <option value="+1787"> PR +1787 </option>
                                            <option value="+974"> QA +974 </option>
                                            <option value="+262"> RE +262 </option>
                                            <option value="+40"> RO +40 </option>
                                            <option value="+70"> RU +70 </option>
                                            <option value="+250"> RW +250 </option>
                                            <option value="+290"> SH +290 </option>
                                            <option value="+1869"> KN +1869 </option>
                                            <option value="+1758"> LC +1758 </option>
                                            <option value="+508"> PM +508 </option>
                                            <option value="+1784"> VC +1784 </option>
                                            <option value="+684"> WS +684 </option>
                                            <option value="+378"> SM +378 </option>
                                            <option value="+239"> ST +239 </option>
                                            <option value="+966"> SA +966 </option>
                                            <option value="+221"> SN +221 </option>
                                            <option value="+381"> CS +381 </option>
                                            <option value="+248"> SC +248 </option>
                                            <option value="+232"> SL +232 </option>
                                            <option value="+65"> SG +65 </option>
                                            <option value="+421"> SK +421 </option>
                                            <option value="+386"> SI +386 </option>
                                            <option value="+677"> SB +677 </option>
                                            <option value="+252"> SO +252 </option>
                                            <option value="+27"> ZA +27 </option>
                                            <option value="+0"> GS +0 </option>
                                            <option value="+34"> ES +34 </option>
                                            <option value="+94"> LK +94 </option>
                                            <option value="+249"> SD +249 </option>
                                            <option value="+597"> SR +597 </option>
                                            <option value="+47"> SJ +47 </option>
                                            <option value="+268"> SZ +268 </option>
                                            <option value="+46"> SE +46 </option>
                                            <option value="+41"> CH +41 </option>
                                            <option value="+963"> SY +963 </option>
                                            <option value="+886"> TW +886 </option>
                                            <option value="+992"> TJ +992 </option>
                                            <option value="+255"> TZ +255 </option>
                                            <option value="+66"> TH +66 </option>
                                            <option value="+670"> TL +670 </option>
                                            <option value="+228"> TG +228 </option>
                                            <option value="+690"> TK +690 </option>
                                            <option value="+676"> TO +676 </option>
                                            <option value="+1868"> TT +1868 </option>
                                            <option value="+216"> TN +216 </option>
                                            <option value="+90"> TR +90 </option>
                                            <option value="+7370"> TM +7370 </option>
                                            <option value="+1649"> TC +1649 </option>
                                            <option value="+688"> TV +688 </option>
                                            <option value="+256"> UG +256 </option>
                                            <option value="+380"> UA +380 </option>
                                            <option value="+971"> AE +971 </option>
                                            <option value="+44"> GB +44 </option>
                                            <option value="+1"> UM +1 </option>
                                            <option value="+598"> UY +598 </option>
                                            <option value="+998"> UZ +998 </option>
                                            <option value="+678"> VU +678 </option>
                                            <option value="+58"> VE +58 </option>
                                            <option value="+84"> VN +84 </option>
                                            <option value="+1284"> VG +1284 </option>
                                            <option value="+1340"> VI +1340 </option>
                                            <option value="+681"> WF +681 </option>
                                            <option value="+212"> EH +212 </option>
                                            <option value="+967"> YE +967 </option>
                                            <option value="+260"> ZM +260 </option>
                                            <option value="+263"> ZW +263 </option>
                                        </select> </div>
                                    <div class="col-8 col-md-8 col-sm-9 col-lg-9 col-xl-9"> <input type="tel" name="Phone" id="Phone" placeholder="Your Phone Number" maxlength="10" minlength="10" pattern="^[0-9]+$" title="Please use only numbers" required/> </div> <small class="text-danger" id="phone_error"></small>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-6"> <label>Your First Name</label> <input type="text" id="First_Name" name="First Name" placeholder="Your First Name" pattern="[a-zA-Z\s]*" title="Please use only alphabets" required/> <small class="text-danger" id="name_error"> </small> </div>
                                <div class="col-md-6"> <label>Your Last Name</label> <input type="text" id="Last_Name" name="Last Name" placeholder="Your Last Name" pattern="[a-zA-Z\s]*" title="Please use only alphabets" required/> <small class="text-danger" id="name_error"> </small> </div>
                            </div>
                            <div class="col-md-12"> <label>Your Work Email</label> <input type="email" name="Email"  placeholder="name@company.com" required/> <small class="text-danger" id="email_error"> </small> </div>
                            <div class="col-md-12"> <label>Your Company’s Name</label> <input type="text" name="Company" id="Company"  placeholder="Company Name" required /> <small class="text-danger" id="company_name_error"> </small> </div>
                            <div class="col-md-12 company_size">
                                <h4>What is your company size?</h4>
                                <div class="row">
                                    <div class="col-6 col-sm-3 col-md-6 col-lg-4 col-xl-3"> <label> 1-10 <input type="radio" id="LEADCF14" name="LEADCF14" value="1-10" required="" /> <span class="checkmark"></span> </label> </div>
                                    <div class="col-6 col-sm-3 col-md-6 col-lg-4 col-xl-3"> <label> 11-50 <input type="radio" id="LEADCF14" name="LEADCF14" value="11-50" required="" /> <span class="checkmark"></span> </label> </div>
                                    <div class="col-6 col-sm-3 col-md-6 col-lg-4 col-xl-3"> <label> 51-250 <input type="radio" id="LEADCF14" name="LEADCF14" value="51-250" required="" /> <span class="checkmark"></span> </label> </div>
                                    <div class="col-6 col-sm-3 col-md-6 col-lg-4 col-xl-3"> <label> 250&lt; <input type="radio" id="LEADCF14" name="LEADCF14" value="250<" required="" /> <span class="checkmark"></span> </label> </div>
                                </div> <small class="text-danger" id="company_size_error"> </small>
                            </div>
                            <div class="col-md-12 looking_sec">
                                <h4>Select at least one skill you're looking for</h4>
                                <div class="check-buttons">
                                    <div class="row">
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="React" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/icn_react.jpg" alt="" />
                                                        <h5>React</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Nodejs" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/node_icon.png" alt="" />
                                                        <h5>Nodejs</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Python" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/python_icon.png" alt="" />
                                                        <h5>Python</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Ruby" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/ruby_icon.png" alt="" />
                                                        <h5>Ruby</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Swift" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/swift_icon.png" alt="" />
                                                        <h5>Swift</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Android" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/android_icon.png" alt="" />
                                                        <h5>Android</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Java" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/java_icon.png" alt="" />
                                                        <h5>Java</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Angular" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/angularJS_icon.png" alt="" />
                                                        <h5>Angular</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Data Engineer" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/data_engineer_icon.png" alt="" />
                                                        <h5>Data Engineer</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="PHP" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/php_icon.png" alt="" />
                                                        <h5>PHP</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Go" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/go_icon.png" alt="" />
                                                        <h5>Go</h5>
                                                    </div>
                                                </span> </label> </div>
                                        <div class="col-lg-6 col-xl-4 col-md-6 col-sm-4 col-6"> <label class="custom-check"> <input type="checkbox" name="LEADCF11" id="LEADCF11" value="Other" /> <span class="check-btn"><i class="fa fa-check"></i>
                                                    <div class="hobbies-icon"> <img src="images/other_icon.png" alt="" />
                                                        <h5>Other</h5>
                                                    </div>
                                                </span> </label> </div> {/*<!-- <small class='text-danger' id = "skill_error" style="font-size:10px; font-weight: bold;">sfasf </small> -->*/} <input type="hidden" name="skill_count" id="skill_count" value="13"/>
                                    </div>
                                </div> <input name="LEADCF12" type="text" id="LEADCF12" placeholder="Enter other skills (100+ available)" style={{display:"none"}}/>
                            </div>
                            <div class="col-md-12 desc_sec">
                                <h4>Select Engagement Type</h4> {/*<!-- <p>Sourcedesk developers are full-time, dedicated resources on your team.</p> -->*/}
                                <div class="duration_grp"> <select name="LEADCF13" id="LEADCF13" class="service_duration" required>
                                        <option value="" selected=""> Duration of service</option>
                                        <option value="Full Time">Full Time</option>
                                        <option value="Part Time">Part Time</option>
                                        <option value="Contractual">Contractual</option>
                                    </select> <small class="text-danger" id="service_duration_error"> </small> </div> <label>Would you like to tell us anything else? <span>(optional)</span></label> <textarea name="Description" id="Description" placeholder="Please, tell us what we need to know before our meeting."></textarea>
                                <p>By submitting this form, I agree with Sourcedesk’s <a href="/">Privacy Policy</a>. <br />I understand I can unsubscribe at any time.</p> <small class="text-danger" id="skill_error" style={{fontSize:"15px", fontWeight: "bold"}}> </small>
                            </div>
                            <div style={{display: "none"}}>
                                <div class="zcwf_row wfrm_fld_dpNn">
                                    <div class="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="LEADCF1">Enquiry Type</label></div>
                                    <div class="zcwf_col_fld"> <select class="zcwf_col_fld_slt" id="LEADCF1" name="LEADCF1">
                                            <option value="-None-">-None-</option>
                                            <option value="VBK Sales Flow">VBK Sales Flow</option>
                                            <option value="SEO-PPC-Website Development Flow">SEO-PPC-Website Development Flow</option>
                                            <option value="CDAP Sales Flow">CDAP Sales Flow</option>
                                            <option selected="" value="SourceDeskio Leads">SourceDeskio Leads</option>
                                        </select>
                                        <div class="zcwf_col_help"></div>
                                    </div>
                                </div>
                                <div class="zcwf_row wfrm_fld_dpNn">
                                    <div class="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="Lead_Source">Lead Source</label></div>
                                    <div class="zcwf_col_fld"><select class="zcwf_col_fld_slt" id="Lead_Source" name="Lead Source">
                                            <option value="-None-">-None-</option>
                                            <option selected="" value="Website">Website</option>
                                            <option value="Google Search">Google Search</option>
                                            <option value="Chat">Chat</option>
                                            <option value="Word of Mouth">Word of Mouth</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Instagram">Instagram</option>
                                            <option value="Inbound Email">Inbound Email</option>
                                            <option value="Inbound Call">Inbound Call</option>
                                        </select>
                                        <div class="zcwf_col_help"></div>
                                    </div>
                                </div>
                                <div class="zcwf_row wfrm_fld_dpNn">
                                    <div class="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="Lead_Status">Lead Status</label></div>
                                    <div class="zcwf_col_fld"><select class="zcwf_col_fld_slt" id="Lead_Status" name="Lead Status">
                                            <option value="-None-">-None-</option>
                                            <option selected="" value="Not Contacted">Not Contacted</option>
                                            <option value="Contacted">Contacted</option>
                                            <option value="Meeting Scheduled">Meeting Scheduled</option>
                                            <option value="Interested">Interested</option>
                                            <option value="Junk Lead">Junk Lead</option>
                                            <option value="Lost Lead">Lost Lead</option>
                                            <option value="Pre-Qualified">Pre-Qualified</option>
                                        </select>
                                        <div class="zcwf_col_help"></div>
                                    </div>
                                </div>
                                <div class="zcwf_row wfrm_fld_dpNn">
                                    <div class="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="LEADCF17">Submitted from page</label></div>
                                    <div class="zcwf_col_fld"><input type="text" id="LEADCF17" name="LEADCF17" maxlength="255" value="https://www.sourcedesk.io/schedule-a-call" />
                                        <div class="zcwf_col_help"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 step_one_btn_sec"> <input type="submit" value="Continue" />
                                <p>Looking for work? <NavLink to="/apply-as-talent">Apply here.</NavLink></p>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
}