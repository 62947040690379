import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import { Helmet } from "react-helmet";

export default function EmployeeReferral(){
  return <>
  <Helmet>
  <title>Sourcedesk Employee Referral Program: Grow Our Team, Earn Rewards</title>
<meta name="description" content="Join Sourcedesk's Employee Referral Program. Expand our team, reap rewards. Elevate your network and earn while growing with us. Discover opportunities today." />
{/* <!----  Head    -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 
  </Helmet>
  <InnerBanner
      InnBanner="images/banner-employee-referral.jpg"
      bgRepeat="no-repeat"
      bgColor="#fafbfd"
      BnrRightImg="images/emp_ref_right_img.png"
      TitleInn={["Join Our ",<span>Referral Initiative!</span>]}
      ParaInn="Use Sourcedesk's Referral Program to recommend qualified candidates—friends, family, or colleagues—to enhance our company's growth and culture. Your insights help shape an exceptional team at Sourcedesk."
      priCTALink="#emp-ref-prog" priCTATitle="Explore the Program" secCTALink={""} secCTATitle={""} priLinkInternal={true}
  />
  
  <section className="full_width emp_ref_line_black fix_padding" id="emp-ref-prog">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Overview of Sourcedesk's <span>Employee Referral Program</span></h2>
              <hr />
              <p>At Sourcedesk, we highly value your team insights for identifying exceptional talent. As a valued member, your candidate recommendations are crucial to our ongoing success</p>
          </div>
          <div className="row">
              <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_left">
                  <ul>
                      <li>
                          <p>If you know a suitable candidate, forward their contact information or CV to <a href="mailto:careers@sourcedesk.io">careers@sourcedesk.io</a></p>
                      </li>
                      <li>
                          <p>Upon successful hiring of the referred candidate, a reward of $500 will be credited to you</p>
                      </li>
                  </ul>
              </div>
              <div className="col-12 col-md-6 col-sm-4 col-xl-4 col-lg-6  Hrng_Gns_filter"><img src="images/emp_ref_star.png" alt="Filter" /></div>
              <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_right">
                  <ul>
                      <li>
                          <p>For eligibility, the referred candidate must be outside our current recruitment process and stay with Sourcedesk for at least three months post-hire to qualify for the reward</p>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </section>
  <section className="full_width emp_ref_benefit fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2><span>Mutual Advantages </span> <br /> Your Referrals, Our Success, Your Reward</h2>
              <hr />
          </div>
          <div className="row Human_img_bottom">
              <div className="col-md-6 Hmn_img_bt_left">
                  <ul>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Identify Excellence: Your insights guide us to the finest candidates.</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Empower Connections: You can assist friends and acquaintances by recommending them.</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Strengthen Recruitment: Collaborate with our team to find high-quality talent.</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Reap Rewards: Receive a monetary incentive for your invaluable assistance!</div>
                      </li>
                  </ul>
              </div>
              <div className="col-md-6 Hmn_img_bt_right"><img src="images/emp_ref_benefit_img.png" alt="" /></div>
          </div>
      </div>
  </section>
  <section className="full_width vettng_video_black foot_gap sdg_advntg_video_black  ">
      <div className="container text_controller text_controller_center">
          <h2>Chart a Course to Excellence by <br /> <span> Engaging with Industry Leaders</span> </h2>
          <div className="cmn_btn_grp "> <NavLink to="/pricing" className="cmn_btn_fill">Explore Our Clear and Comprehensive Pricing Guide</NavLink> <NavLink to="/schedule-a-call" className="cmn_btn_stroke">Spark Partnerships with Visionary Experts</NavLink> </div>
      </div>
  </section>
  </>
}