import { Helmet } from "react-helmet";
// import BlogsSlider from "../Components/BlogsSlider";
import BlogsSliderpopular from "../Components/BlogsSliderpopular";
// import InnerBannerForm from "../Components/InnerBannerForm";
import InnerBanner from "../Components/InnerBanner";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";
import { useLocation } from "react-router-dom";

const get_blog_data = "https://backend.sourcedesk.io/api/v1/get-blog-list-updated";

export default function Blogs() {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;

  const [blogList, setblogList] = useState([]); // Store the blog posts
  const [loading, setLoading] = useState(true); // Loading state
  const [page, setPage] = useState(1); // Current page
  const [hasMore, setHasMore] = useState(true); // Whether more blogs are available
  useEffect(() => {
    getBloglist();
  }, []);

  const getBloglist = () => {
    setLoading(true);
    axios
      .get(get_blog_data)
      .then((res) => {
        setblogList(res.data.blogs);
        setPage(res.data.nextpage);
        setHasMore(res.data.hasMore);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function loadMore() {
    setLoading(true);
    axios
      .get(get_blog_data, {
        params: {
          page: page,
        },
      })
      .then((res) => {
        setblogList((blogList) => [...blogList, ...res.data.blogs]); // Append new blogs
        setPage(res.data.nextpage);
        setHasMore(res.data.hasMore);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <Helmet>
        <title>
          Sourcedesk Blog: Trends, and Expertise in Business and Technology
        </title>
        <meta
          name="description"
          content="Stay informed with the Sourcedesk Blog. Explore trends and expertise in business and technology. Elevate your knowledge and stay ahead in your industry."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Sourcedesk Blog: Trends, and Expertise in Business and Technology"
        />
        <meta
          property="og:description"
          content="Stay informed with the Sourcedesk Blog. Explore trends and expertise in business and technology. Elevate your knowledge and stay ahead in your industry."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Sourcedesk Blog: Trends, and Expertise in Business and Technology"
        />
        <meta
          name="twitter:description"
          content="Stay informed with the Sourcedesk Blog. Explore trends and expertise in business and technology. Elevate your knowledge and stay ahead in your industry."
        />
      </Helmet>
      {loading && <Loader />}
      <InnerBanner
        TitleInn="Blog"
        InnBanner="images/blog_bnr.png"
        BnrRightImg="images/blog_grphcs1.png"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        // placeHolderText="Email"
        // submitText="Subscribe"
        priCTALink=""
        secCTALink=""
      />
      <BlogsSliderpopular heading={["Most ", <span>Popular</span>]} />
      <section
        className="full_width foot_gap fix_padding"
        style={{ backgroundColor: "rgb(249, 252, 252)" }}
      >
        <div className="container">
          <div class="text_controller text_controller_center">
            <h2>
              Leadership<span> Recent</span>
            </h2>
            <hr />
          </div>
          <div className="thoughts blogs-grid">
            <div className="row gy-4">
              {blogList.map((item, i) => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-3" key={i}>
                    <div className="ldrshp_thght_items">
                      <img src={item.blog_image} alt={item.blog_img_alt} />
                      <p className="date">{item.blog_date}</p>
                      <p className="title"> {item.blog_title} </p>
                      <p className="text"> {item.blog_short_desc}... </p>
                      <Link
                        to={`/blog-details/${item.blog_slug}`}
                        className="cmn_btn_stroke"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>

            {hasMore ? (
              <div className="row mt-4">
                <div className="col text-center">
                  <button
                    className="cmn_btn_fill text-nowrap me-0"
                    onClick={(e) => {
                      e.preventDefault();
                      loadMore();
                    }}
                  >
                    Load more
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>
      {/* <BlogsSliderpopular heading={["Most", <span> Popular</span>]} />
      <BlogsSlider
        heading={["Leadership", <span> Recent</span>]}
        bgColor="#f9fcfc"
      /> */}
      {/* <section class="full_width blog_pgbk foot_gap fix_padding">
        <div class="container">
          <div class="text_controller text_controller_center">
            <h2>
              Stay informed with the Sourcedesk Newsletter -
              <br />
              <span>subscribe now!</span>
            </h2>
            <p>
              Get exclusive insights on remote work, hiring, and engineering
              management delivered straight to your inbox by signing up for our
              newsletter.
            </p>
          </div>
          <div class="pgbk_form">
            <form action="post">
              <div class="cmn_btn_grp">
                <input
                  type="text"
                  placeholder="Full name"
                  class="cmn_btn_stroke"
                />
                <input
                  type="email"
                  placeholder="Enter your email"
                  class="cmn_btn_stroke"
                />
                <input type="submit" value="Subscribe" class="cmn_btn_fill" />
              </div>
            </form>
          </div>
        </div>
      </section> */}
    </>
  );
}
