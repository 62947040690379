import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import InnerBanner from "../Components/InnerBanner";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";
import { useLocation } from "react-router-dom";

export default function Events() {
  const get_event_data = "https://backend.sourcedesk.io/api/v1/get-event-list";
  const [eventList, seteventList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    geteventlist();
  }, []);

  const geteventlist = () => {
    setLoading(true);
    axios
      .get(get_event_data)
      .then((res) => {
        seteventList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>
          Discover Events at Sourcedesk: Join Us for Innovation and Networking
        </title>
        <meta
          name="description"
          content="Explore our Sourcedesk events for innovation and networking. Join us in transformative experiences. Elevate your journey with cutting-edge opportunities."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Discover Events at Sourcedesk: Join Us for Innovation and Networking"
        />
        <meta
          property="og:description"
          content="Explore our Sourcedesk events for innovation and networking. Join us in transformative experiences. Elevate your journey with cutting-edge opportunities."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Discover Events at Sourcedesk: Join Us for Innovation and Networking"
        />
        <meta
          name="twitter:description"
          content="Explore our Sourcedesk events for innovation and networking. Join us in transformative experiences. Elevate your journey with cutting-edge opportunities."
        />
      </Helmet>
      <InnerBanner
        InnBanner="images/enents_banner.png"
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg="images/dot_dot_circle1.png"
        BnrLeftImg=""
        TitleInn={[
          <span>Events:</span>,
          <br />,
          "Join Us for a ",
          <br />,
          "Memorable Experience",
        ]}
        ParaInn=""
        priCTALink={""}
        priCTATitle={""}
        secCTALink={""}
        secCTATitle={""}
      />
      {loading && <Loader />}
      <section class="full_width fix_padding upcoming_events">
        <div class="container">
          <div class="text_controller text_controller_center">
            <h2>Upcoming Events</h2>
            <hr />
            <p>
              Find the latest upcoming events of our company held across the
              world. We invite you to participate and acquire knowledge about
              the latest trends within the industry, innovative concepts, and
              successful business case studies. These events offer valuable
              insights into a variety of subjects within the rapidly evolving
              business landscape.
            </p>
          </div>
        </div>
      </section>
      {eventList.length > 0 ? (
        <section class="full_width leadership_thaught past_events fix_padding">
          <div class="container">
            <div class="text_controller text_controller_center">
              <h2>Past Events</h2>
              <hr />
              <p>
                Did you miss one of our events? No need to worry! We've compiled
                a brief overview of previous occasions for you.
              </p>
            </div>
            <div class="row gy-4">
              {eventList.map((item, i) => {
                return (
                  <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <Link
                      to={`/event-detail/${item.slug}`}
                      className="card-anchor"
                    >
                      <div class="past_evnts_items">
                        <div class="img-wrapper">
                          <img src={item.featured_image} alt={item.title} />
                        </div>
                        <h2>{item.title}</h2>
                        <p>{item.venue}</p>
                        <h4> {item.event_date}</h4>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}
