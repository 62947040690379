import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function SeeAllSkills() {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;
  const get_developers_skill_role =
    "https://backend.sourcedesk.io/api/v1/get-developer-skills-roles";
  const [skillList, setskillList] = useState([]);
  const [developerList, setdeveloperList] = useState([]);

  useEffect(() => {
    getallSkills();
  }, []);

  const getallSkills = () => {
    axios
      .get(get_developers_skill_role)
      .then((res) => {
        
        setskillList(res.data.developer_skills);
        setdeveloperList(res.data.developer_roles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>See All Skills and Take Your Specific Requirements</title>
        <meta
          name="description"
          content="Elevate your projects with our expert hiring solutions. Search and hire developers via precise tech skills and roles. Revolutionize your team quickly with Sourcedesk."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="See All Skills and Take Your Specific Requirements"
        />
        <meta
          property="og:description"
          content="Elevate your projects with our expert hiring solutions. Search and hire developers via precise tech skills and roles. Revolutionize your team quickly with Sourcedesk."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="See All Skills and Take Your Specific Requirements"
        />
        <meta
          name="twitter:description"
          content="Elevate your projects with our expert hiring solutions. Search and hire developers via precise tech skills and roles. Revolutionize your team quickly with Sourcedesk."
        />
      </Helmet>
      <div>
        <section className="full_width Home_banner inner_banner apply_talent_banner hire_banner all_skill_banner">
          <div
            className="Home_banner_sub"
            style={{
              background:
                "#fafbfd url(images/hire_banner.jpg) no-repeat 50% 50%",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12Hom_bnr_left inner_bnr_left">
                  <div className="banner_text">
                    <h1>
                      <span>Expert Hiring Solutions </span> <br />
                      Discover the Best Resources by Skill and Role
                    </h1>
                    <p>
                      {" "}
                      Build Your Team Quickly and Effectively with Our Precise
                      Skill-Based Hiring
                    </p>
                  </div>

                  {/* <div className="hire_banner_search">
                        <input type="search" placeholder="Search" />
                        <input type="submit" />
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {skillList.length > 0 ? (
          <section className="full_width hire_skill fix_padding">
            <div className="container">
              <div className="text_controller text_controller_center">
                <h2>
                  Hire Developers by <span> Tech Skills</span>
                </h2>
                <hr />
              </div>

              <div className="hire_all_skill">
                <div className="sdg_devlopers_technologies">
                  <ul>
                    {skillList.map((item, i) => (
                      <li>
                        <NavLink to={item.page_url}>
                          <img src={item.logo} alt={item.title} />
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div></div>
        )}

        {developerList.length > 0 ? (
          <section className="full_width hire_skill hire_rolls hire_single_rolls fix_padding">
            <div className="container">
              <div className="text_controller text_controller_center">
                <h2>
                  Hire Developers by <span>Roles</span>
                </h2>
                <hr />
              </div>

              <div className="hire_all_skill hire_all_roles">
                <div className="sdg_devlopers_technologies">
                  <ul>
                    {developerList.map((roleItm, i) => (
                      <li>
                        <NavLink
                          to={`/hire-our-genius-mind/${roleItm.role_slug}`}
                        >
                          <img src={roleItm.role_logo} alt={roleItm.role_alt} />
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div></div>
        )}
        <section className="full_width vettng_video_black foot_gap sdg_advntg_video_black ">
          <div className="container text_controller text_controller_center">
            <h2>
              Revolutionize your project with the <br /> expertise of our{" "}
              <span> exceptional developers</span>{" "}
            </h2>
            <div className="cmn_btn_grp ">
              <NavLink to="/how-it-works" className="cmn_btn_fill">
                Explore Our Talent Pool for Your Next Big Project
              </NavLink>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
