import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function ApplyAsTalent() {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;
  return (
    <>
      <Helmet>
        <title>
          Be a Valuable Pre-Vetted Resource - Apply As a Talent | Sourcedesk
        </title>
        <meta
          name="description"
          content="Become a valuable resource! Apply as talent at Sourcedesk. Join our pre-vetted community for rewarding opportunities. Your journey to success starts here."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Be a Valuable Pre-Vetted Resource - Apply As a Talent | Sourcedesk"
        />
        <meta
          property="og:description"
          content="Become a valuable resource! Apply as talent at Sourcedesk. Join our pre-vetted community for rewarding opportunities. Your journey to success starts here."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Be a Valuable Pre-Vetted Resource - Apply As a Talent | Sourcedesk"
        />
        <meta
          name="twitter:description"
          content="Become a valuable resource! Apply as talent at Sourcedesk. Join our pre-vetted community for rewarding opportunities. Your journey to success starts here."
        />
      </Helmet>
      <InnerBanner
        InnBanner="images/apply_banner_bg.jpg"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/apply_banner_right_img.png"
        TitleInn={[
          <span>Boost Your Software Career</span>,
          <br />,
          " Secure Your Future with Remote Work",
        ]}
        ParaInn="Join elite developers' network for high-paying remote jobs in top Canadian and American companies."
        priCTALink="/current-openings"
        priCTATitle="Take the First Step Towards Success"
        secCTALink={""}
        secCTATitle={""}
      />
      <TrustedSlider defaultTitle={"Trusted By"} />
      <section className="full_width SDGvsOTHERS apply_job_position fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              <span>Join Our Dynamic Team</span> <br /> Exciting Remote Jobs
              with Growth Opportunities
            </h2>
            <hr />
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Role</th>
                  <th scope="col">Jobs Offered</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ width: "30%" }}>Developer</th>
                  <td style={{ width: "70%" }}>
                    Full-stack developer, Front-end developer, Back-end
                    developer, Mobile developer, DevOps Engineer, Software
                    Engineer, UI/UX Designer, Database Administrator
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>Tester</th>
                  <td style={{ width: "70%" }}>
                    Manual Tester, Automation Tester, Performance Tester,
                    Security Tester, Mobile Tester, Test Manager, Test
                    Architect, Test Engineer
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>Digital Marketer</th>
                  <td style={{ width: "70%" }}>
                    SEO Specialist, Social Media Specialist, PPC Specialist,
                    Email Marketing Specialist, Content Marketing Specialist
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>Accountant and Finance</th>
                  <td style={{ width: "70%" }}>
                    Financial Analyst, Accountant, Bookkeeper, Payroll
                    Specialist, Tax Accountant
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "30%" }}>Virtual Assistant</th>
                  <td style={{ width: "70%" }}>
                    Administrative Assistant, Personal Assistant, Data Entry
                    Specialist, Research Assistant, E-Commerce Assistant
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <section
        className="full_width We_harness appy_why_choose fix_padding"
        data-inviewport="scale-in"
      >
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              <span>Unlock Your Potential</span>
              <br /> Discover Unique Benefits and Perks for Our Candidates
            </h2>
            <hr />
          </div>
          <div className="row We_harness_bottom">
            <div className="col-lg-5 We_hrns_image">
              {" "}
              <svg viewBox="0 0 610 610">
                <circle cx="306" cy="292" r="290" fill="none"></circle>
              </svg>
              <div className="We_hrns_image_grp">
                {" "}
                <img
                  src="images/apply_why_choose.png"
                  alt="Flower"
                  className="We_hrns_image_main"
                />{" "}
              </div>
            </div>
            <div className="col-lg-7 We_hrns_usp">
              <ul>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon1.png" alt="" />
                  </div>
                  <p>Comprehensive Medical Benefits</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon2.png" alt="" />
                  </div>
                  <p>Performance &amp; Review Bonus Program</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon3.png" alt="" />
                  </div>
                  <p>Access to Prestigious Co-Working Spaces</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon4.png" alt="" />
                  </div>
                  <p>Daily Meals and Additional Convenience Perks</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon5.png" alt="" />
                  </div>
                  <p>Complimentary Gym Membership</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon6.png" alt="" />
                  </div>
                  <p>Dedicated Onboarding and Ongoing Support </p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon7.png" alt="" />
                  </div>
                  <p>Extensive Training and Development Opportunities</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon8.png" alt="" />
                  </div>
                  <p>Exciting ICT Opportunities to Work Abroad</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon9.png" alt="" />
                  </div>
                  <p>State-of-the-Art Computers &amp; Accessories</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon10.png" alt="" />
                  </div>
                  <p>Engaging Company Events &amp; Perks</p>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/harness_icon11.png" alt="" />
                  </div>
                  <p>Team Building Retreats and Recognition Awards</p>
                </li>
              </ul>
              <div className="cmn_btn_grp">
                <NavLink to="/current-openings" className="cmn_btn_fill">
                  Seize the Opportunity
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width Human_img apply_skills fix_padding">
        <div className="container">
          <div className="text_controller">
            <h2>
              <span>Join Our Premier Community</span> <br />
              Essential Skills for Success and Excellence
            </h2>
            <hr />
          </div>
          <div className="row Human_img_bottom">
            <div className="col-md-6 Hmn_img_bt_left">
              <ul>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    Exceptional Communication Skills
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    Profound Technical Proficiency
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">Cultural Alignment </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    Strong Problem-Solving Abilities
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    Effective Self-Discipline and Time Management{" "}
                  </div>
                </li>
              </ul>
              <div className="cmn_btn_grp">
                <NavLink to="/current-openings" className="cmn_btn_fill">
                  Take Action Now
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 Hmn_img_bt_right">
              <img src="images/apply_skill_right_img.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="full_width talent_sec our_success fix_padding">
        <div className="container">
          <div className="talent_sec_top">
            <div className="tlnt_sec_top_left text_controller">
              {/* Real Testimonials, Tangible Outcomes */}
              <h3>
                Inspiring Stories from Thriving <br /> <span>Candidates</span>
              </h3>
            </div>
            <div className="tlnt_sec_top_right">
              <p>
                {" "}
                Real Testimonials, <br /> Tangible Outcomes
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 our_success_text">
              {" "}
              <img src="images/promotion.png" alt="" />
              <h4>Wilson James Taylor, Senior AI/ML Developer</h4>
              <p>
                As an experienced AI/ML developer, I was looking for a role that
                would challenge me and allow me to work on cutting-edge
                projects. Sourcedesk's team truly understood my skillset and
                career aspirations. They took the time to understand my goals
                and matched me with a company that was the perfect fit. Their
                streamlined process made the entire job search hassle-free, and
                I'm now working on projects that excite me and align with my
                expertise.
              </p>
            </div>
            <div className="col-12 col-md-4 our_success_text">
              {" "}
              <img src="images/promotion.png" alt="" />
              <h4>Emily Johnson, Node.js Developer</h4>
              <p>
                Finding the right job can be a daunting task, but Sourcedesk
                made the process seamless for me. Their team's deep
                understanding of the Node.js ecosystem and their extensive
                network of companies allowed them to match me with a role that
                perfectly complemented my skills and experience level. I was
                impressed by their personalized approach and their commitment to
                ensuring a mutually beneficial fit for both the candidate and
                the employer.
              </p>
            </div>
            <div className="col-12 col-md-4 our_success_text">
              {" "}
              <img src="images/promotion.png" alt="" />
              <h4>Michael Thompson, QA Tester</h4>
              <p>
                As a recent graduate in the field of software testing, I was
                unsure of how to navigate the job market. Sourcedesk's team
                provided invaluable guidance and support throughout the entire
                process. They took the time to understand my strengths,
                interests, and career goals and then matched me with a company
                that not only aligned with my skill set as a QA tester but also
                provided me with the opportunity to learn and grow. Thanks to
                their expertise, I landed my dream job and am now part of an
                amazing team working on cutting-edge projects.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width Hiring_Genius remote_dvloper apply_talent fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h4 className="fs-heading-2">
              Join the <span>Sourcedesk</span> Talent Community in 4 Simple
              Steps
            </h4>
            <hr />
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_left">
              <ul>
                <li>
                  <p className="title">Create Your profile</p>
                  <p>
                    Share your name, skills, location, salary, and experience.
                  </p>
                </li>
                <li>
                  <p className="title">Take Assessments and Interviews</p>
                  <p>
                    Answer questions and participate in technical interviews.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-6 col-xl-4 col-lg-4  Hrng_Gns_filter">
              {" "}
              <img src="images/apply_talent_img.png" alt="Filter" />{" "}
            </div>
            <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_right">
              <ul>
                <li>
                  <p className="title">Receive Job Offers</p>
                  <p>Get matched with premier US and Canadian companies.</p>
                </li>
                <li>
                  <p className="title">Launch Your Dream Career</p>
                  <p>End your job hunting journey with Sourcedesk</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            {" "}
            <NavLink to="/current-openings" className="cmn_btn_fill">
              Initiate Your Progress
            </NavLink>{" "}
          </div>
        </div>
      </section>
    </>
  );
}
