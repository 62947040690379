import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
export default function BlogsSliderpopular({ heading, bgColor }) {
  const get_popblog_data =
    "https://backend.sourcedesk.io/api/v1/get-popular-blog-list";
  const [popblogList, setpopblogList] = useState([]);

  const fromMainJs = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: true,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#c2c2c2",
        }}
      ></div>
    ),
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getpopBloglist();
  }, []);

  const getpopBloglist = () => {
    axios
      .get(get_popblog_data)
      .then((res) => {
        setpopblogList(res.data);

        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const settings = {
  //     dots: true,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     arrows: true,
  // };

  if (popblogList.length > 0) {
    return (
      <section
        className="full_width  fix_padding"
        style={{ backgroundColor: bgColor }}
      >
        {/* leadership_thaught blog_popular */}
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>{heading}</h2>
            <hr />
          </div>
          <div className="slider-container thoughts" id="cmn_slider_btn">
            {/* className="thoughts" id="cmn_slider_btn" */}
            <Slider {...fromMainJs}>
              {popblogList.map((res, i) => {
                return (
                  <div>
                    <div className="ldrshp_thght_items">
                      {" "}
                      <img src={res.blog_image} alt={res.blog_img_alt} />
                      {/* <h4>{res.blog_date}</h4> */}
                      <p className="date">{res.blog_date}</p>
                      {/* <h2> {res.blog_title} </h2> */}
                      <p className="title">{res.blog_title}</p>
                      <p className="desc">{res.blog_short_desc}... </p>{" "}
                      <Link
                        to={`/blog-details/${res.blog_slug}`}
                        className="cmn_btn_stroke"
                      >
                        {" "}
                        Read More{" "}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>{" "}
        {/*<div className="cmn_btn_grp cmn_btn_grp_center"><a href="" className="cmn_btn_fill">View All</a></div>*/}
      </section>
    );
  }
}
