import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
function TestimonialsSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button></button>,
  };
  return (
    <div className="slider-testimonials" id="cmn_slider_btn">
      <Slider {...settings}>
        <div className="slider-testimonials-slides">
          <p>
            Working with Sourcedesk has been a game-changer for our company.
            Their remote AI developers are incredibly skilled and responsive,
            and they easily integrate with our team. They bring fresh,
            innovative solutions to the table and communicate with clarity and
            professionalism. What stands out is their dedication and genuine
            interest in our project's success. Thanks to their expertise, we've
            seen impressive results and efficient progress.{" "}
          </p>
          <h3>Jane M.</h3>
          <h4>Chief Technical Officer</h4>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                className="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            Sourcedesk’s remote graphic designers exceeded our expectations!
            Their creativity and attention to detail brought our vision to life
            perfectly. Communication was smooth, and they were always eager to
            refine and enhance their work based on our feedback. Their
            professionalism and enthusiasm made the entire process enjoyable.
          </p>
          <h3>Micheal W.</h3>
          <h4>CEO</h4>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                className="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            Hiring Sourcedesk for our remote accounting needs was a fantastic
            decision. Their team is accurate, reliable, and always on top of our
            finances. They handle everything with professionalism and keep us
            informed every step of the way. It’s such a relief to have experts
            we trust managing our accounts. Thank you, Sourcedesk!
          </p>
          <h3>William H.</h3>
          <h4>Head Manager</h4>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                className="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            Sourcedesk’s remote PPC services have truly transformed our ad
            campaigns. Their data-driven strategies and precise targeting
            significantly boosted our ROI. The team’s expertise in keyword
            optimization and ad placement was evident from the start. They
            provided detailed reports and insightful recommendations, making
            tracking performance and adjusting tactics easy. If you’re looking
            for expert PPC management, Sourcedesk is the way to go!
          </p>
          <h3>Justin K.</h3>
          <h4>Digital Marketer</h4>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                className="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            We struggled with scalability issues in our Node.js app until we
            teamed up with Sourcedesk. Their remote developers tackled our
            performance bottlenecks with advanced techniques and optimized our
            server-side logic. The result was a much faster and more reliable
            application. Their expertise and clear communication turned our
            challenges into smooth, efficient solutions. Sourcedesk’s team was
            instrumental in revitalizing our project.
          </p>
          <h3>David H.</h3>
          <h4>Head Developer</h4>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                className="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
      </Slider>
    </div>
  );
}
function MileStoneSlider() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    arrows: false,
    //   arrows:true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          // dots: true,
          // arrows: false
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          // dots: true,
          // arrows: false
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          // dots: true,
          // arrows: false
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button></button>,
  };
  return (
    <div className="slider-milestones" id="cmn_slider_btn">
      <Slider {...settings}>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2008</div>
            <p>
              Sourcedesk commenced operations with web development services.
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2010</div>
            <p>
              Our business expanded with the introduction of digital marketing
              services.
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2012</div>
            <p>
              Sourcedesk engineered products for the needs of e-commerce
              businesses and mobile applications.
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2014</div>
            <p>We introduced ERP and CRM products to our portfolio.</p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2016</div>
            <p>
              Sourcedesk initiated virtual bookkeeping services for businesses.
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2018</div>
            <p>
              Integrating resources such as virtual assistance, IT consulting,
              and outsourcing solutions was introduced in the company.
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2021</div>
            <p>
              We merged our workforce into dedicated resources specializing in
              IT and software development.
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2023</div>
            <p>
              Sourcedesk began its collaboration with the Canada Digital
              Adoption Program (CDAP).
            </p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2024</div>
            <p>We developed and expanded our workforce into AI/ML products.</p>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default function About() {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;
  return (
    <>
      <Helmet>
        <title>
          About Sourcedesk | Empowering Businesses with Top IT Talent
        </title>
        <meta
          name="description"
          content="At Sourcedesk, collaboration and innovation fuel our solutions, ensuring project success and client satisfaction. We go beyond expectations to drive business growth."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="About Sourcedesk | Empowering Businesses with Top IT Talent"
        />
        <meta
          property="og:description"
          content="At Sourcedesk, collaboration and innovation fuel our solutions, ensuring project success and client satisfaction. We go beyond expectations to drive business growth."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="About Sourcedesk | Empowering Businesses with Top IT Talent"
        />
        <meta
          name="twitter:description"
          content="At Sourcedesk, collaboration and innovation fuel our solutions, ensuring project success and client satisfaction. We go beyond expectations to drive business growth."
        />
      </Helmet>
      <section className="full_width Home_banner inner_banner apply_talent_banner hire_banner">
        <div
          className="Home_banner_sub"
          style={{
            background: "#f9fcfc url(images/banner-about-us.jpg)",
            maxHeight: "400px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12Hom_bnr_left inner_bnr_left">
                <div className="banner_text">
                  <h1 className="mb-0">
                    About <span>Us</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding">
        <div className="container">
          <div className="rol">
            <div className="col">
              <div className="text_controller text_controller_center">
                <h2>
                  <span>Empower Your Business </span>with Top Talent
                </h2>
                <hr />
              </div>
              <p className="text-center">
                Sourcedesk is a team of skilled professionals who recruit top
                talent to grow your business.{" "}
              </p>
              <div className="row exp-boxes">
                <div className="col">
                  <div className="exp-box">
                    <div className="num">04</div>
                    <h3>Office Locations</h3>
                    <p>Headquarters in Canada</p>
                  </div>
                </div>
                <div className="col">
                  <div className="exp-box">
                    <div className="num">200+</div>
                    <h3>Talented Minds</h3>
                  </div>
                </div>
                <div className="col">
                  <div className="exp-box">
                    <div className="num">1300+</div>
                    <h3>Clients</h3>
                  </div>
                </div>
                <div className="col">
                  <div className="exp-box">
                    <div className="num">12+</div>
                    <h3>Years of Experience</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fix_padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text_controller">
                <h2>Who We Are</h2>
                <hr />
              </div>
              <p>
                Sourcedesk offers website design, software development, online
                marketing, mobile app development, creative writing, accounting,
                bookkeeping, and talent acquisition services. We connect
                businesses with pre-vetted, English-speaking professionals.
                Having served over 1,300 clients in Canada and the US across
                various industries, we provide top-tier talent at affordable
                prices, simplifying recruitment and driving business growth.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img src="images/abt-us-que-mark.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="fix_padding section-commitment">
        <div className="container">
          <div className="text_controller">
            <h2>
              Experience Sourcedesk's Commitment to Driving Your Business Growth
            </h2>
            <hr />
          </div>

          <div className="d-none d-lg-block skilltabSec">
            <div className="three_tab">
              <Tabs
                defaultActiveKey="0"
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="0" title="Our Approach">
                  <div className="container">
                    <div
                      className="row align-items-center row-commitment"
                      style={{
                        background: "#f7f9f9",
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our approach is based on collaboration and innovation,
                          allowing us to consistently meet and surpass project
                          goals. We are dedicated to delivering practical
                          solutions that not only meet but exceed our clients'
                          expectations.
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="1" title="Our Mission">
                  <div className="container">
                    <div
                      className="row align-items-center row-commitment"
                      style={{
                        background: "#f7f9f9",
                      }}
                    >
                      <div className="col-8">
                        <p>
                          We are dedicated to providing innovative hiring
                          solutions that meet the highest quality standards
                          while maintaining a sustainable and responsible
                          business model. Our commitment ensures that our
                          practices benefit both our clients and employees.
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="2" title="Awards & Recognition">
                  <div className="container">
                    <div
                      className="row align-items-center row-commitment"
                      style={{
                        background: "#f7f9f9",
                      }}
                    >
                      <div className="col-8">
                        <p>
                          At Sourcedesk, we deeply value the dedication and
                          passion our employees bring to their work. To show our
                          appreciation, we organize office events and award
                          ceremonies to celebrate and recognize their hard work
                          and achievements.
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="d-lg-none v_accordion">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Our Approach</Accordion.Header>
                <Accordion.Body className="">
                  <div className="container">
                    <div
                      className="row align-items-center row-commitment"
                      style={{
                        background: "#f7f9f9",
                      }}
                    >
                      <div className="col-12 col-sm-8">
                        <p>
                          Our approach is based on collaboration and innovation,
                          allowing us to consistently meet and surpass project
                          goals. We are dedicated to delivering practical
                          solutions that not only meet but exceed our clients'
                          expectations.{" "}
                        </p>
                      </div>
                      <div className="col-sm-4 d-none d-sm-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Our Mission</Accordion.Header>
                <Accordion.Body className="">
                  <div className="container">
                    <div
                      className="row align-items-center row-commitment"
                      style={{
                        background: "#f7f9f9",
                      }}
                    >
                      <div className="col-12 col-sm-8">
                        <p>
                          We are dedicated to providing innovative hiring
                          solutions that meet the highest quality standards
                          while maintaining a sustainable and responsible
                          business model. Our commitment ensures that our
                          practices benefit both our clients and employees.
                        </p>
                      </div>
                      <div className="col-sm-4 d-none d-sm-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Awards & Recognition</Accordion.Header>
                <Accordion.Body className="">
                  <div className="container">
                    <div
                      className="row align-items-center row-commitment"
                      style={{
                        background: "#f7f9f9",
                      }}
                    >
                      <div className="col-12 col-sm-8">
                        <p>
                          At Sourcedesk, we deeply value the dedication and
                          passion our employees bring to their work. To show our
                          appreciation, we organize office events and award
                          ceremonies to celebrate and recognize their hard work
                          and achievements.
                        </p>
                      </div>
                      <div className="col-sm-4 d-none d-sm-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
      <section
        className="full_width section-testimonials fix_padding"
        style={{
          background: "url(images/aiml_testimony_bg.jpg) no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-md-center">
            <div className="col-md-4">
              <h2>
                <span>What Our </span>
                <br />
                <span>Clients Have to Say</span>
              </h2>
            </div>
            <div className="col-md-8 mt-5 mt-md-0">
              <TestimonialsSlider />
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
      <section
        className="full_width fix_padding section-cta-horizontal"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text_controller">
                <h2 className="mb-2">
                  Profound Cultural Values Provide the Foundation of Exceptional
                  Results
                </h2>
                <p>
                  We cultivate a collaborative, low-hierarchy culture where
                  employees are empowered to own their work, not just complete
                  tasks.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <NavLink
                to="/culture-and-values"
                className="cmn_btn_fill text-nowrap d-inline-block"
              >
                Sourcedesk Cultural Advantage
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
      <section
        className="full_width fix_padding section-milestones"
        style={{ background: "#f7f9f9" }}
      >
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Our <span>Milestone</span>
            </h2>
            <hr />
          </div>
          <div className="row">
            <div className="col">
              <MileStoneSlider />
            </div>
          </div>
        </div>
      </section>
      <section
        className="full_width fix_padding section-cta-horizontal"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text_controller">
                <h2 className="mb-2">
                  Social Responsibility Remains a Core Value
                </h2>
                <p>
                  We are committed to fostering diversity, equality, and the
                  holistic growth of individuals, whether they are our clients,
                  business partners, or employees.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <NavLink
                to="/social-responsibility"
                className="cmn_btn_fill text-nowrap d-inline-block"
              >
                Social Responsibilities
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
      <section
        className="full_width fix_padding foot_gap"
        style={{
          background:
            "url('images/about-us-cta-bg.png') no-repeat center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="text_controller text_controller_center">
              <h2 className="text-white">
                Receive a Complimentary Consultation
              </h2>
              <p className="text-white">
                Get in touch with a Sourcedesk expert to learn more about our
                services and solutions.{" "}
              </p>
              <NavLink
                to="https://www.sourcedesk.io/schedule-a-call"
                className="cmn_btn_fill cmn_btn_fill_white text-nowrap me-0"
              >
                Hire an Expert
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
    </>
  );
}
