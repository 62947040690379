import { useState } from "react";

export default function LocationMap() {
  const [curAddress, setCurAddress] = useState(1);
  function handleClick(add) {
    setCurAddress(add);
  }

  return (
    <div className="footer_sub_map">
      <img
        src={window.location.origin + "/images/world_map.png"}
        alt="Location map"
      />
      <div id="horizontalTab2">
        <ul className="resp-tabs-list">
          <li
            onClick={() => handleClick(1)}
            className={`resp-tab-item ${
              curAddress === 1 ? "resp-tab-active" : ""
            }`}
          ></li>
          <li
            onClick={() => handleClick(2)}
            className={`resp-tab-item ${
              curAddress === 2 ? "resp-tab-active" : ""
            }`}
          ></li>
          <li
            onClick={() => handleClick(3)}
            className={`resp-tab-item ${
              curAddress === 3 ? "resp-tab-active" : ""
            }`}
          ></li>
          <li
            onClick={() => handleClick(4)}
            className={`resp-tab-item ${
              curAddress === 4 ? "resp-tab-active" : ""
            }`}
          ></li>
        </ul>

        <div className="resp-tabs-container">
          <div className={curAddress === 1 ? "d-block" : "d-none"}>
            <div className="footer_sub_loaction ">
              <p className="country">Canada</p>
              <p className="city">Toronto</p>
              <p>
                5399 Eglinton Ave. <br /> West Suite 212,
                <br /> Toronto, ON, M9C 5K6
              </p>
            </div>
          </div>

          <div className={curAddress === 2 ? "d-block" : "d-none"}>
            <div className="footer_sub_loaction">
              <p className="country">Colombia</p>
              <p className="city">Medellin</p>
              <p>
                Cra. 42 #3 Sur 81 Torre 1 Piso 15,
                <br /> El Poblado,
                <br /> Medellín, Antioquia, Colombia
              </p>
            </div>
          </div>

          <div className={curAddress === 3 ? "d-block" : "d-none"}>
            <div className="footer_sub_loaction">
              <p className="country">India</p>
              <p className="city">Kolkata</p>
              <p>
                Shaila Tower, EP-204, <br /> Salt Lake, Sector-V,
                <br /> Kolkata - 700091 <br />
                info@sourcedeskglobal.com
              </p>
            </div>
          </div>

          <div className={curAddress === 4 ? "d-block" : "d-none"}>
            <div className="footer_sub_loaction">
              <p className="country">USA</p>
              <p className="city">New York</p>
              <p>
                One Liberty Plaza, <br /> 165 Broadway,
                <br /> Suite 2301, New York, 10006
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
