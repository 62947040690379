import React, { useRef, useState } from "react";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
// import ProgressingDNA from "../Components/ProgressingDNA";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import AnimatedDNA from "../DNA/AnimatedDNA";
import { useLocation } from "react-router-dom";

function DistinctVettingProcess() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null); // Reference to the video element
  function handlePlay(e) {
    // console.log(e);
    // videoRef.current.play();
    // setIsPlaying(true);
    if (videoRef.current) {
      // Check if videoRef.current exists
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      console.error("Video element not found.");
    }
  }
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;
  return (
    <>
      <Helmet>
        <title>
          Discover Sourcedesk's Distinct Vetted Process for Hiring Geniuses
        </title>
        <meta
          name="description"
          content="Looking to hire a genius? Sourcedesk offers a distinct approach to hiring. Explore how we redefine talent acquisition and elevate your team-building journey."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Discover Sourcedesk's Distinct Vetted Process for Hiring Geniuses"
        />
        <meta
          property="og:description"
          content="Looking to hire a genius? Sourcedesk offers a distinct approach to hiring. Explore how we redefine talent acquisition and elevate your team-building journey."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Discover Sourcedesk's Distinct Vetted Process for Hiring Geniuses"
        />
        <meta
          name="twitter:description"
          content="Looking to hire a genius? Sourcedesk offers a distinct approach to hiring. Explore how we redefine talent acquisition and elevate your team-building journey."
        />

        {/* <script type="application/ld+json">
          {`{
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              "@id": "https://www.sourcedesk.io/#breadcrumb",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://www.sourcedesk.io/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Why Sourcedesk",
                  "item": "javascript:void(0)"
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Distinct Vetting Process",
                  "item": "https://www.sourcedesk.io/distinct-vetting-process"
                }
              ]
            }`}
        </script>
        <script type="application/ld+json">
          {`{
              "@context": "http://schema.org",
              "@type": "Review",
              "itemReviewed": {
                "@type": "Product",
                "name": "Distinct Vetting Process",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4.5",
                  "bestRating": "5",
                  "worstRating": "1",
                  "ratingCount": "100"
                }
              },
              "author": {
                "@type": "Person",
                "name": "SourceDesk"
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "4.5",
                "bestRating": "5",
                "worstRating": "1"
              },
              "reviewBody": "Review Body"
            }`}
        </script> */}
      </Helmet>
      <InnerBanner
        InnBanner="images/inner_banner_grphcs2.png"
        bgRepeat="no-repeat"
        BnrRightImg="images/inr_bnr_img2.png"
        TitleInn={[
          <span>Enhancing Remote Team Building</span>,
          " Leveraging AI and Human Intelligence for Exceptional Talent Acquisition",
        ]}
        ParaInn=""
        priCTALink=""
        priCTATitle=""
        secCTALink={""}
        secCTATitle={""}
      />
      <TrustedSlider defaultTitle="Trusted By" />
      <section className="full_width Hiring_Genius home_genius embark_journey fix_padding border_top">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              {" "}
              Navigating the Vetting Process <br /> A Detailed Examination{" "}
            </h2>
            <p>
              {" "}
              Discover the meticulous seven-stage journey every candidate
              undergoes before joining Sourcedesk{" "}
            </p>
            <hr />
          </div>
          <div className="row d-none d-xl-flex">
            <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4 Hrng_Gns_left">
              <ul>
                <li>
                  <h3>Job Application Screening</h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Using ML and AI, our advanced system efficiently analyzes
                    résumés, identifying the best-fit candidates for your job
                    requirements. It continuously improves accuracy by learning
                    from your past hiring decisions.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    Online Test <span>(40%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    After shortlisting candidates, our system assigns them 8 to
                    12 tailored online tests, including mandatory English and IQ
                    exams, for a comprehensive skills evaluation.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    In-person Interview<span>(20%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    We conduct thorough evaluations, considering technical
                    proficiency and interpersonal dynamics, to find qualified
                    candidates progressing to the next stages of our selection
                    process.{" "}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4 Hrng_Gns_filter">
              {" "}
              <img
                src="images/fliter_process2.png"
                alt="meticulous seven-stage vetting process"
              />
              <div className="Hrng_Gns_filter_point">
                <div className="hng_gns_finalPoint">7</div>
                <h2>
                  {" "}
                  <span>Hiring Genius </span> (Top 10%){" "}
                </h2>
                <div className="Hrng_Gns_li_tooltip">
                  {" "}
                  After rigorous evaluation, we identify the top 10% as
                  Sourcedesk's Hiring Geniuses, pre-vetted candidates ready to
                  make a significant impact.{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4 Hrng_Gns_right">
              <ul>
                <li>
                  <h3>
                    {" "}
                    HR Screening <span> (60%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Successful candidates progress to HR interviews, where our
                    experienced team evaluates their professional background,
                    communication skills, commitment, and personality traits to
                    ensure a seamless fit with Sourcedesk and our valued
                    clients.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    AI-Powered Technical Test <span>(30%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Our advanced AI assessment games evaluate candidates'
                    abilities through immersive simulations of real-world
                    scenarios. These tests assess problem-solving skills and
                    cultural fit, while our AI-powered video interviews gauge
                    work style and compatibility.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    Live Coding Challenge <span>(15%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Our live coding challenge assesses candidates' coding
                    skills, problem-solving abilities, and composure under
                    pressure in real-time, observed by a reviewer.{" "}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row d-flex d-xl-none">
            {/* <div className="col-12 col-md-4 col-xl-4 col-lg-4 Hrng_Gns_left">
                      <ul>
                          <li className="justify-content-center">
                              <h3>Job Application Screening</h3>
                              <div className="Hrng_Gns_li_tooltip"> Using ML and AI, our advanced system efficiently analyzes résumés, identifying the best-fit candidates for your job requirements. It continuously improves accuracy by learning from your past hiring decisions. </div>
                          </li>
                          <li className="justify-content-center">
                              <h3> HR Screening <span> (60%)</span> </h3>
                              <div className="Hrng_Gns_li_tooltip"> Successful candidates progress to HR interviews, where our experienced team evaluates their professional background, communication skills, commitment, and personality traits to ensure a seamless fit with Sourcedesk and our valued clients. </div>
                          </li>
                          <li className="justify-content-center">
                              <h3> Online Test <span>(40%)</span> </h3>
                              <div className="Hrng_Gns_li_tooltip"> After shortlisting candidates, our system assigns them 8 to 12 tailored online tests, including mandatory English and IQ exams, for a comprehensive skills evaluation. </div>
                          </li>
                      </ul>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4 col-lg-4 Hrng_Gns_filter"> <img src="images/fliter_process2.png" alt="meticulous seven-stage vetting process" />
                      <div className="Hrng_Gns_filter_point">
                          <div className="hng_gns_finalPoint">7</div>
                          <h2> <span>Hiring Genius </span> (Top 10%) </h2>
                          <div className="Hrng_Gns_li_tooltip"> After rigorous evaluation, we identify the top 10% as Sourcedesk's Hiring Geniuses, pre-vetted candidates ready to make a significant impact. </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-4 col-xl-4 col-lg-4 Hrng_Gns_right">
                      <ul>
                          <li className="justify-content-center">
                              <h3> AI-Powered Technical Test <span>(30%)</span> </h3>
                              <div className="Hrng_Gns_li_tooltip"> Our advanced AI assessment games evaluate candidates' abilities through immersive simulations of real-world scenarios. These tests assess problem-solving skills and cultural fit, while our AI-powered video interviews gauge work style and compatibility. </div>
                          </li>
                          <li className="justify-content-center">
                              <h3> In-person Interview<span>(20%)</span> </h3>
                              <div className="Hrng_Gns_li_tooltip"> We conduct thorough evaluations, considering technical proficiency and interpersonal dynamics, to find qualified candidates progressing to the next stages of our selection process. </div>
                          </li>
                          <li className="justify-content-center">
                              <h3> Live Coding Challenge <span>(15%)</span> </h3>
                              <div className="Hrng_Gns_li_tooltip"> Our live coding challenge assesses candidates' coding skills, problem-solving abilities, and composure under pressure in real-time, observed by a reviewer. </div>
                          </li>
                      </ul>
                  </div> */}
            <div className="col-md-6">
              <ul>
                <li>
                  <h3>Job Application Screening</h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Using ML and AI, our advanced system efficiently analyzes
                    résumés, identifying the best-fit candidates for your job
                    requirements. It continuously improves accuracy by learning
                    from your past hiring decisions.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    HR Screening <span> (60%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Successful candidates progress to HR interviews, where our
                    experienced team evaluates their professional background,
                    communication skills, commitment, and personality traits to
                    ensure a seamless fit with Sourcedesk and our valued
                    clients.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    Online Test <span>(40%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    After shortlisting candidates, our system assigns them 8 to
                    12 tailored online tests, including mandatory English and IQ
                    exams, for a comprehensive skills evaluation.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    AI-Powered Technical Test <span>(30%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Our advanced AI assessment games evaluate candidates'
                    abilities through immersive simulations of real-world
                    scenarios. These tests assess problem-solving skills and
                    cultural fit, while our AI-powered video interviews gauge
                    work style and compatibility.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    In-person Interview<span>(20%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    We conduct thorough evaluations, considering technical
                    proficiency and interpersonal dynamics, to find qualified
                    candidates progressing to the next stages of our selection
                    process.{" "}
                  </div>
                </li>
                <li>
                  <h3>
                    {" "}
                    Live Coding Challenge <span>(15%)</span>{" "}
                  </h3>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    Our live coding challenge assesses candidates' coding
                    skills, problem-solving abilities, and composure under
                    pressure in real-time, observed by a reviewer.{" "}
                  </div>
                </li>
                <li>
                  <h2>
                    {" "}
                    <span>Hiring Genius </span> (Top 10%){" "}
                  </h2>
                  <div className="Hrng_Gns_li_tooltip">
                    {" "}
                    After rigorous evaluation, we identify the top 10% as
                    Sourcedesk's Hiring Geniuses, pre-vetted candidates ready to
                    make a significant impact.{" "}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <img
                src="images/fliter_process2.png"
                alt="meticulous seven-stage vetting process"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="full_width every_talent fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Rigorous Pre-Vetting of Talent Network <br />
              to Meet High Standards of Excellence
            </h2>
            <hr className="hr_black_bg" />
          </div>
          <div className="row We_harness_bottom">
            <div className="col-lg-5 We_hrns_image">
              <img
                src="images/networking.png"
                alt="Pre-Vetting of Talent Network"
              />
            </div>
            <div className="col-lg-7 We_hrns_usp">
              <ul>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/every_icon1.png" alt="Icon" />
                  </div>
                  <h4>Effective Communication</h4>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/every_icon2.png" alt="Icon" />
                  </div>
                  <h4>Technically Super Sound</h4>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/every_icon3.png" alt="Icon" />
                  </div>
                  <h4> Cultural Fit </h4>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/every_icon4.png" alt="Icon" />
                  </div>
                  <h4>Problem-Solving Abilities</h4>
                </li>
                <li>
                  <div className="We_hrns_usp_icon">
                    <img src="images/every_icon5.png" alt="Icon" />
                  </div>
                  <h4>Self-Discipline and Time Management</h4>
                </li>
              </ul>
              <div className="cmn_btn_grp">
                <NavLink
                  to="/schedule-a-call"
                  className="cmn_btn_fill cmn_btn_fill_white"
                >
                  Unlock Exceptional Talent
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="full_width fix_padding section-cta-horizontal"
        style={{ background: "#f9fcfc" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text_controller">
                <h2 className="mb-2">
                  Sourcedesk Advantage for Hiring Remote Talent
                </h2>
                <p>
                  Discover the benefit of hiring remote talent from Sourcedesk
                  to elevate your business operations.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <NavLink
                to="/culture-and-values"
                className="cmn_btn_fill text-nowrap d-inline-block"
              >
                Sourcedesk Advantage
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width Accelerate_creation fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Streamline Team Building for <span> Rapid Progress </span>
            </h2>
            <hr />
            <p>
              Our efficient process empowers you to build an exceptional team
              effortlessly, with just three simple steps.
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <ul>
                <li>
                  <div className="Acclrte_crton_number">1</div>
                  <div className="Acclrte_crton_text">
                    <h2>Intelligent Skill Matching</h2>
                    <p>
                      Utilize our intuitive machine learning and AI platform to
                      effortlessly identify the perfect match for any role
                      within your organization. Our advanced technology ensures
                      accurate skill matching, saving you time and effort.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="Acclrte_crton_number">2</div>
                  <div className="Acclrte_crton_text">
                    <h2>Engage with Top Talent</h2>
                    <p>
                      Effortlessly schedule interviews with curated,
                      highly-skilled technologists tailored to your team's
                      needs. Access the best industry talent through our
                      extensive network.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="Acclrte_crton_number">3</div>
                  <div className="Acclrte_crton_text">
                    <h2>Seamless Integration and Support</h2>
                    <p>
                      Experience seamless onboarding with payroll, compliance,
                      and ongoing support, so you can focus on driving your
                      business forward.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 Acclrte_crton_img">
              <img
                src="images/aclarte_img.png"
                alt="Streamline Effortless team building"
              />
            </div>
            <div className="cmn_btn_grp cmn_btn_grp_center">
              <NavLink to="/schedule-a-call" className="cmn_btn_fill">
                Build your Dream Team
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section
        className="full_width   why_sdg_distinct fix_padding is-inViewport"
        data-inviewport="scale-in"
      >
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Why <span>Sourcedesk?</span>
            </h2>
            <hr />
          </div>
          {/* <div className="row">
            <div className="col-6 col-md-3 col-sm-3 col-xl-3 why_sdg_row">
              <h4>
                Extensive Collection <br /> of Talented Individuals
              </h4>
              <div className="two_dots">
                <img src="images/6lines.png" alt="" />
              </div>
              <h4>
                TAT is Very Fast
                <br /> (Within 5 Days)
              </h4>
            </div>
            <div className="col-6 col-md-3 col-sm-3 col-xl-3 why_sdg_row">
              <h4>
                Top 10%
                <br /> Vetted Professionals
              </h4>
              <div className="two_dots">
                <img src="images/6lines.png" alt="" />
              </div>
              <h4>
                Huge Cost
                <br /> Savings for Clients (Up to 66%)
              </h4>
            </div>
            <div className="col-6 col-md-3 col-sm-3 col-xl-3 why_sdg_row">
              <h4>
                All Time <br />
                Zone Matching
              </h4>
              <div className="two_dots">
                <img src="images/6lines.png" alt="" />
              </div>
              <h4>
                Full IT Support <br />
                and Prestigious Co-Working Spaces
              </h4>
            </div>
            <div className="col-6 col-md-3 col-sm-3 col-xl-3 why_sdg_row">
              <h4>
                2-Week
                <br />
                Free Trial Period
              </h4>
              <div className="two_dots">
                <img src="images/6lines.png" alt="" />
              </div>
              <h4>
                Dedicated Account
                <br />
                Manager to Ensure Success
              </h4>
            </div>

            <div className="why_sdg_graphics">
                <svg viewBox="0 0 1000 300">                    
                    <path d="M2,200  Q140,85 146,86  Q165,70   252,138  Q367,225  381,227  Q395,235   519,141  Q586,92   600,87  Q627,67 722,147  Q820,221 842,228 T1000,110" fill="none"></path>
                    <path d="M2,110  Q95,191 150,227  Q163,230   264,155  Q379,78  379,85  Q403,67   560,198  Q615,239   623,225  Q690,178 769,123  Q830,77 850,83 T1000,200" fill="none"></path>
                    </svg>
            </div>
            <ProgressingDNA />
          </div> */}
          <div className="d-none d-md-block">
            <div className="row why_sdg_row">
              <div className="col-3">
                <h4>
                  {" "}
                  Extensive Collection <br /> of Talented Individuals{" "}
                </h4>
              </div>
              <div className="col-3">
                <h4>
                  {" "}
                  Top 10% <br /> Vetted Professionals{" "}
                </h4>
              </div>
              <div className="col-3">
                <h4>
                  {" "}
                  All Time <br /> Zone Matching{" "}
                </h4>
              </div>
              <div className="col-3">
                <h4>
                  {" "}
                  2-Week <br /> Free Trial Period{" "}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col overflow-hidden d-flex justify-content-center">
                <AnimatedDNA />
              </div>
            </div>
            <div className="row why_sdg_row">
              <div className="col-3">
                <h4>
                  {" "}
                  TAT is Very Fast <br /> (Within 5 Days){" "}
                </h4>
              </div>
              <div className="col-3">
                <h4>
                  {" "}
                  Huge Cost <br /> Savings for Clients (Up to 66%){" "}
                </h4>
              </div>
              <div className="col-3">
                <h4>
                  {" "}
                  Full IT Support <br /> and Prestigious Co-Working Spaces{" "}
                </h4>
              </div>
              <div className="col-3">
                <h4>
                  {" "}
                  Dedicated Account <br /> Manager to Ensure Success{" "}
                </h4>
              </div>
            </div>
          </div>
          <div className="d-md-none d-block">
            <div className="row why_sdg_row gy-5">
              <div className="col-6 d-flex align-items-center flex-column">
                <h4>
                  {" "}
                  Extensive Collection <br /> of Talented Individuals{" "}
                </h4>
                <div className="two_dots">
                  {" "}
                  <img src="images/6lines.png" alt="" />{" "}
                </div>
                <h4>
                  {" "}
                  TAT is Very Fast <br /> (Within 5 Days){" "}
                </h4>
              </div>
              <div className="col-6 d-flex align-items-center flex-column">
                <h4>
                  {" "}
                  Top 10% <br /> Vetted Professionals{" "}
                </h4>
                <div className="two_dots">
                  {" "}
                  <img src="images/6lines.png" alt="" />{" "}
                </div>
                <h4>
                  {" "}
                  Huge Cost <br /> Savings for Clients (Up to 66%){" "}
                </h4>
              </div>
              <div className="col-6 d-flex align-items-center flex-column">
                <h4>
                  {" "}
                  All Time <br /> Zone Matching{" "}
                </h4>
                <div className="two_dots">
                  {" "}
                  <img src="images/6lines.png" alt="" />{" "}
                </div>
                <h4>
                  {" "}
                  Full IT Support <br /> and Prestigious Co-Working Spaces{" "}
                </h4>
              </div>
              <div className="col-6 d-flex align-items-center flex-column">
                <h4>
                  {" "}
                  2-Week <br /> Free Trial Period{" "}
                </h4>
                <div className="two_dots">
                  {" "}
                  <img src="images/6lines.png" alt="" />{" "}
                </div>
                <h4>
                  {" "}
                  Dedicated Account <br /> Manager to Ensure Success{" "}
                </h4>
              </div>
            </div>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">
              Hire Developers
            </NavLink>
          </div>
        </div>
      </section>
      <section className="full_width our_vettng_process fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Our <br /> <span>Vetting process</span>
            </h2>
            <hr />
          </div>
          <div className="row">
            <div className="col">
              {/* {!isPlaying && ( */}
              <div
                className={`vettng_video ${!isPlaying ? "d-flex" : "d-none"}`}
                style={
                  {
                    // background: "url(images/jas-video-cover.webp)",
                    // backgroundPosition: "center",
                    // backgroundSize: "contain",
                    // backgroundRepeat: "no-repeat",
                  }
                }
              >
                <img src="images/jas-video-cover.webp" alt="" />
                <button
                  // className={`${isPlaying ? "d-none" : "d-block"}`}
                  onClick={(e) => handlePlay(e)}
                >
                  <div className="vdo_btn">
                    <img src="images/vdo_triangle.png" alt="Icon" />
                  </div>
                </button>
                {/* <h2>Video Title Here</h2> */}
              </div>
              {/* )} */}

              <div
                className={`video-wrapper ${isPlaying ? "d-block" : "d-none"}`}
              >
                <video
                  src="/images/jas-saran-speech-compressed.mp4"
                  preload="none"
                  poster="/images/video-poster.png"
                  controls={isPlaying}
                  ref={videoRef}
                ></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width foot_gap vettng_video_black">
        <div className="container">
          <h2>
            Ready to Engage With Our Genius Minds?
            <span> Let’s Get Started</span>
          </h2>
          <div className="cmn_btn_grp">
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">
              Hire Our Genius
            </NavLink>
            <NavLink to="/apply-as-talent" className="cmn_btn_stroke">
              Apply Here
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

export default DistinctVettingProcess;
