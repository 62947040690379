import { NavLink } from "react-router-dom";

export default function ComingSoon() {
  return (
    <section className="full_width Home_banner">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text_controller text_controller_center">
              <h1 style={{ maxWidth: "800px", marginTop: "5rem" }}>
                Something <span>Amazing</span> is Coming Your Way!
              </h1>
              <p style={{ maxWidth: "800px", marginBottom: "3rem" }}>
                We're working hard to bring you an incredible experience. Stay
                tuned for updates and get ready to explore what we've been
                building just for you!
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <NavLink
              to="/"
              className="cmn_btn_fill d-inline-block me-0"
              style={{ marginBottom: "5rem" }}
            >
              Back to Homepage
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}
