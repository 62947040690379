import React, { useEffect, useState } from 'react';
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import Slider from "react-slick";






export default function OurTeamTabs() {

  const get_team_data = "https://backend.sourcedesk.io/api/v1/get-team-list";
  const get_team_data_by_catid = "https://backend.sourcedesk.io/api/v1/get-team-list-by-catid";

  const [ourTeam, setteamData] = useState([]);
  const [execTeam, setexecData] = useState([]);
  const [adminTeam, setadminData] = useState([]);

  const getteamsData = () => {
    axios
      .get(get_team_data)
      .then((res) => {

        setteamData(res.data);

      
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getexecData = () => {
    axios.get(get_team_data_by_catid, {
      params: {
        catId: 12
      }
    })
      .then((res) => {

        setexecData(res.data);

        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getadminData = () => {
    axios.get(get_team_data_by_catid, {
      params: {
        catId: 10
      }
    })
      .then((res) => {

        setadminData(res.data);

        //console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getteamsData();
  }, []);

  useEffect(() => {
    getexecData();
  }, []);

  // useEffect(() => {
  //   getadminData();
  // }, []);
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight:true,
  nav: false,
  appendDots: dots => (
    <div>
      <ul> {dots} </ul>
    </div>
  ),
  customPaging: i => (
    <div >
    </div>
  ),
};
// style={{ margin: "0px" }}
// style={{
//   width: "10px",
//   height: "10px",
//   borderRadius: "50%", 
//   backgroundColor: "#c2c2c2",
// }}

  if (ourTeam.length > 0) {
    return (<>
      <section className="full_width fix_padding section-executive" style={{  }}>
      {execTeam.length > 0 ? (
        <div className="container">
          <div className="row">
            <div className="col bg_dark_blue">
              <div class="text_controller text_controller_center">
                <h2>Meet the people who lead our success</h2>
                <hr />
              </div>
            </div>
          </div>
          <div className="row d-none d-sm-flex gy-4 row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 justify-content-center">

          {execTeam.map((el2, i) => (
            <div className="col">
              <div className="ourTeamtab_items executive-card-full">
                <img src={el2.photo} alt={el2.name} />
                <h2>{el2.name}</h2>
                <h4>{el2.designation}</h4>
              </div>
            </div>
            ))}

            
          </div>
          <div className="row d-flex d-sm-none">
            <div className="col">
            <Slider {...settings}>
                  {execTeam.map((el2, i) => (
                    <div className="col">
                      <div className="ourTeamtab_items executive-card-full">
                        <img src={el2.photo} alt={el2.name} />
                        <h2>{el2.name}</h2>
                        <h4>{el2.designation}</h4>
                      </div>
                    </div>
                    ))}
            </Slider>
            </div>
          </div>
        </div>
        ): (
          <div></div>
      )}

      </section>

      {/* <section className="full_width fix_padding" style={{paddingTop: "0px"}}>
      {adminTeam.length > 0 ? (
        <div className="container">
          <div className="row">
            <div className="col">
              <div class="text_controller text_controller_center">
                <h2>HR & Admin</h2>
                <hr />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
          {adminTeam.map((el3, i) => (
            <div className="col">
              <div className="ourTeamtab_items executive-card">
                <img src={el3.photo} alt={el3.name} />
                <h2>{el3.name}</h2>
                <h4>{el3.designation}</h4>
              </div>
            </div>
             ))}
            
            
          </div>
        </div>
        ): (
          <div></div>
        )}
      </section> */}


      <section className="full_width ourTeamtab fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              The Catalysts of Our Clients’ Success: <br /> Unveiling Our
              Passionate and Driven Team
            </h2>
            <hr />
          </div>
          <div className="d-none d-md-block">
            <Tabs className='justify-content-center'>
              {ourTeam.map((el, i) => (
                <Tab eventKey={el.dept} title={el.dept} key={i}>
                  <div className="row">
                    {el.members.map((el, i) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={i}
                      >
                        <div className="ourTeamtab_items">
                          <img src={el.photo} alt={el.name} />
                          <h2>{el.name}</h2>
                          <h4>{el.designation}</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab>
              ))}
            </Tabs>

          </div>
          <div className="d-block d-md-none">

            <Accordion defaultActiveKey={null} >
              {ourTeam.map((el, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header>{el.dept}</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      {el.members.map((member, index) => (
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-3"
                          key={index}
                        >
                          <div className="ourTeamtab_items">
                            <img src={member.photo} alt={member.name} />
                            <h2>{member.name}</h2>
                            <h4>{member.designation}</h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
    </>
    );
  }
}


