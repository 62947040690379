import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
import Slider from "react-slick";
import Accordion from "react-bootstrap/Accordion";

export default function CulturalValues() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    centerMode: true, // Enables center mode
    centerPadding: "0", // Removes extra padding around the center slide
    // initialSlide: 1, // Start with the second slide as the center

    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div></div>,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      title: "Leadership",
      content:
        "<p>Sourcedesk strongly believes that leadership is not a title that is granted to an individual, but rather, defined by actions. Being a leader involves leading by example and taking on responsibilities that will assist in the betterment of the whole.</p>",
    },
    {
      title: "Customers",
      content:
        "<p>We have a strong customer-centric structure and approach. We encourage our clients to give their input and work consistently towards helping them maximize value, boost profitability, and gain a competitive edge. Their goals are our goals, and their success is our success.</p>",
    },
    {
      title: "Transparency",
      content:
        "<p>At Sourcedesk, we promote an open and honest exchange of information. We focus on operating in a completely transparent manner to ensure success for our customers and our company. We also encourage new ideas and feedback sharing to strengthen our abilities.</p>",
    },
    {
      title: "Sustainability",
      content:
        "<p>A sustainable business is one that survives any hardships as its well-connected to healthy social, economic and environmental systems. Such businesses create value not just for their clients, but also the communities they affect. That’s why sustainability is so important to Sourcedesk.</p>",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Culture and Values - Sourcedesk</title>
        <meta
          name="description"
          content="Our corporate culture is built on the following approaches to leadership, customers, transparency, and sustainability."
        />
      </Helmet>
      <section className="full_width Home_banner inner_banner apply_talent_banner hire_banner">
        <div
          className="Home_banner_sub"
          style={{
            backgroundColor: "#f9fcfc",
            maxHeight: "400px",
            backgroundImage: "url(images/cultural-values-bg.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12Hom_bnr_left inner_bnr_left">
                <div className="banner_text">
                  <h1 className="mb-0">
                    <span>Culture</span> and <span>Values</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding section-our-culture">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Our Culture</h2>
            <hr />
            <p>
              Our corporate culture is built on the following approaches to
              leadership, customers, transparency, and sustainability.
            </p>
          </div>
          <div className="row  d-none d-md-flex">
            <div className="col-md-4">
              <div className="sdg-tabs">
                {tabs.map((tab, index) => (
                  <button
                    onClick={() => setActiveTab(index)}
                    key={index}
                    className={`${activeTab === index ? "active" : ""}`}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8">
              <div className="sdg-tab-data">
                <div
                  className="data"
                  dangerouslySetInnerHTML={{
                    __html: tabs[activeTab].content,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row d-flex d-md-none">
            <div className="col v_accordion">
              <Accordion defaultActiveKey={0}>
                {tabs.map((e, i) => (
                  <Accordion.Item eventKey={i} key={i}>
                    <Accordion.Header>{e.title}</Accordion.Header>
                    <Accordion.Body>
                      <div
                        className="data"
                        dangerouslySetInnerHTML={{
                          __html: tabs[activeTab].content,
                        }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="section-our-values full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Our values</h2>
            <hr />
            <p>The backbone of Sourcedesk is its core values of:</p>
          </div>
          <div className="row">
            <div className="col">
              <Slider {...settings}>
                <div className="value-slide">
                  <img src="images/values-bar-graph.png" alt="" />
                  <h3>Quality</h3>
                  <p>
                    We strive to ensure quality embodies everything we do, from
                    our deliverables to our interactions with clients and each
                    other. We aim for excellence and constantly aspire to
                    improve ourselves.
                  </p>
                </div>
                <div className="value-slide">
                  <img src="images/values-bar-graph.png" alt="" />
                  <h3>Integrity</h3>
                  <p>
                    By always following ethical business practices, a business
                    always ensures its integrity even in the most difficult
                    situations. This is something we constantly practice and
                    always expect from employees.
                  </p>
                </div>
                <div className="value-slide">
                  <img src="images/values-bar-graph.png" alt="" />
                  <h3>Obligation</h3>
                  <p>
                    Sourcedesk has several obligations--to its employees, to the
                    community, to the environment, and above all to its clients.
                    By adhering to our values and goals, we fulfill those
                    obligations.
                  </p>
                </div>
                <div className="value-slide">
                  <img src="images/values-bar-graph.png" alt="" />
                  <h3>Innovation</h3>
                  <p>
                    Our workplace is a melting pot of minds where unique and
                    innovative ideas are conceptualized, developed, and
                    implemented. The creative work environment this fosters is
                    the birthplace of the unique and innovative approaches we
                    provide our clients.
                  </p>
                </div>
                <div className="value-slide">
                  <img src="images/values-bar-graph.png" alt="" />
                  <h3>Synergy</h3>
                  <p>
                    Only by having all of its composite parts working together
                    in harmony can a system operate efficiently. That is the
                    reason the entire Sourcedesk team works towards singular goals with
                    clear objectives, despite everyone involved having diverse
                    backgrounds.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section
        className="full_width fix_padding foot_gap"
        style={{ backgroundColor: "#f7fbfc" }}
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <div className="text_controller">
              <h2 className="text-end pe-sm-3 pe-lg-4 border-right">
                <span>READY TO</span> JOIN US?
              </h2>
            </div>
            <div className="ps-sm-3 ps-lg-4 me-sm-5 text-center text-sm-start">
              <p>
                Join a team of passionate and dedicated professionals, committed
                to collaboration, education, and proactive problem-solving.
              </p>
              <p className="theme_col">
                Take the first step towards your new career
              </p>
            </div>
            <NavLink
              className="cmn_btn_fill text-nowrap ms-sm-5 me-0"
              to="/apply-as-talent"
            >
              Apply Now
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
