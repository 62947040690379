import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

export default function BlogDetails() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const get_blog_details = "https://backend.sourcedesk.io/api/v1/get-blog-details";
  //const get_blog_details = "https://backend.sourcedesk.io/api/v1/get-blog-details-staging";
  const [blogDetails, setblogDetails] = useState([]);
  const { id } = useParams();

  const curr_url = window.location.href;

  useEffect(() => {
    getBlogdetails();
  }, []);

  const getBlogdetails = () => {
    setLoading(true);
    axios
      .get(get_blog_details, {
        params: {
          slug: id,
        },
      })
      .then(function (response) {
        

        if (response.data.response == 1) {
          setblogDetails(response.data.blog);
        } else {
          navigate("/blog");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const getPrev = (param) => {
    setLoading(true);
    axios
      .get(get_blog_details, {
        params: {
          slug: param,
        },
      })
      .then(function (response) {
        

        if (response.data.response == 1) {
          setblogDetails(response.data.blog);
          window.scrollTo(0, 0);
        } else {
          navigate("/blog");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const getNext = (param) => {
    setLoading(true);
    axios
      .get(get_blog_details, {
        params: {
          slug: param,
        },
      })
      .then(function (response) {
        

        if (response.data.response == 1) {
          setblogDetails(response.data.blog);
          window.scrollTo(0, 0);
        } else {
          navigate("/blog");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  if (blogDetails.length > 0) {
    return (
      <>
        <Helmet>
          <title>{blogDetails[0].meta_title}</title>

          <meta name="title" content={blogDetails[0].meta_title} />
          <meta name="description" content={blogDetails[0].meta_description} />
          {/* <!-- Canonical & hrefLang --> */}
          <link rel="canonical" href={curr_url} />
          <link rel="alternate" hrefLang="x-default" href={curr_url} />
          <link rel="alternate" hrefLang="en-US" href={curr_url} />

          {/* <!-- Twitter Card meta tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sourcedeskgl" />
          <meta name="twitter:title" content={blogDetails[0].meta_title} />
          <meta
            name="twitter:description"
            content={blogDetails[0].meta_description}
          />
          <meta
            name="twitter:image"
            content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg"
          />
          {/* <!-- Facebook Open Graph tags --> */}
          <meta property="og:url" content={curr_url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={blogDetails[0].meta_title} />
          <meta
            property="og:description"
            content={blogDetails[0].meta_description}
          />
          <meta
            property="og:image"
            content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg"
          />
          {/* <!-- Additional Open Graph tags for better customization --> */}
          <meta property="og:image:width" content="2400" />
          <meta property="og:image:height" content="1260" />
          <meta property="og:image:alt" content="SourceDesk" />
          <meta property="og:locale" content="en_US" />
          {blogDetails[0].shcema_script}
        </Helmet>
        {loading && <BlogDetailsLoader />}
        {!loading && (
          <section class="full_width blog_detail_body fix_padding">
            <div class="container">
              <div class="text_controller text_controller">
                <h1>{blogDetails[0].blog_title}</h1>
                <ul class="d-flex blg__ctgy">
                  <li>{blogDetails[0].blog_date}</li>
                  <li>{blogDetails[0].blog_category}</li>
                </ul>
                <hr />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: blogDetails[0].blog_description,
                }}
              ></div>
            </div>
            <div className="container">
              <div class="cmn_btn_grp" style={{ width: "auto" }}>
                {blogDetails[0].prev_blog_slug ? (
                  <button
                    onClick={() => getPrev(blogDetails[0].prev_blog_slug)}
                    class="cmn_btn_fill"
                  >
                    Previous Blog
                  </button>
                ) : (
                  <div></div>
                )}

                {blogDetails[0].next_blog_slug ? (
                  <button
                    onClick={() => getNext(blogDetails[0].next_blog_slug)}
                    class="cmn_btn_fill"
                  >
                    Next Blog
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </section>
        )}
      </>
    );
  } else {
    return <BlogDetailsLoader />;
  }
}

function BlogDetailsLoader() {
  return (
    <section class="full_width blog_detail_body fix_padding">
      <div class="container">
        <div class="text_controller text_controller">
          <h1>
            <Skeleton />
          </h1>
          <p>
            <Skeleton count={5} />
          </p>
          <p>
            <Skeleton count={5} />
          </p>
          <p>
            <Skeleton count={5} />
          </p>
          <p>
            <Skeleton count={5} />
          </p>
          <p>
            <Skeleton count={5} />
          </p>
          <p>
            <Skeleton count={5} />
          </p>
        </div>
      </div>
    </section>
  );
}
