import { Helmet } from "react-helmet";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loader from "../Components/Loader";

export default function EventDetails() {
  const get_event_details =
    "https://backend.sourcedesk.io/api/v1/get-event-details";
  const [eventDetails, seteventDetails] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  var settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#c2c2c2",
        }}
      ></div>
    ),
  };

  useEffect(() => {
    getEventdetails();
  }, []);

  const getEventdetails = () => {
    setLoading(true);
    axios
      .get(get_event_details, {
        params: {
          slug: id,
        },
      })
      .then(function (response) {
        
        seteventDetails(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  if (eventDetails.length > 0) {
    return (
      <>
        <Helmet>
          <title>{eventDetails[0].title}</title>
        </Helmet>

        <section class="full_width Home_banner inner_banner apply_talent_banner our_team_banner">
          <div
            class="Home_banner_sub hide-bg-early-1400-events-details"
            style={{
              background:
                "#f9fcfc url(" +
                window.location.origin +
                "/images/events-pg-bg-with-mark.png) no-repeat center/cover",
            }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <div class="text_controller">
                    <h1>
                      <span>{eventDetails[0].title}</span>
                      <br />
                      {eventDetails[0].city}
                    </h1>
                  </div>
                  <p class="event-date-tag no-arrow mt-3">
                    {eventDetails[0].event_date}
                  </p>
                </div>

                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 inner_bnr_right">
                  <img
                    src={
                      window.location.origin + "/images/events-detail-hero.png"
                    }
                    alt="graphics"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {eventDetails[0].event_gallery.length > 0 ? (
          <section className="full_width leadership_thaught fix_padding slider-events-details">
            <div className="container">
              <div className="text_controller text_controller_center">
                <h2>Event Gallery</h2>
                <hr />
              </div>

              <div className="events-detail" id="cmn_slider_btn">
                <div className="slider-container">
                  <Slider {...settings}>
                    {eventDetails[0].event_gallery.map((item, i) => {
                      return (
                        <div>
                          <div className="img-wrapper-outer">
                            <div className="img-wrapper">
                              {" "}
                              <img
                                src={item.gallery_image}
                                alt={eventDetails[0].title}
                              />{" "}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div></div>
        )}

        <section class="full_width fix_padding section-event-venue">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div class="text_controller">
                  <h2>
                    Venue: <span>{eventDetails[0].venue}</span>
                  </h2>
                  <p class="event-date-tag mt-3">
                    {eventDetails[0].event_date}
                  </p>
                  <hr />
                </div>
                <ul class="mb-4">
                  <li>
                    <p>
                      <strong>Genre:</strong> {eventDetails[0].genre}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Participation Area:</strong>{" "}
                      {eventDetails[0].participation_area}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Outline:</strong> {eventDetails[0].outline}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Speakers/ Participants:</strong>{" "}
                      {eventDetails[0].speakers}
                    </p>
                  </li>
                </ul>
                <h3 class="text_controller">
                  <span>Summary:</span>
                </h3>
                <div
                  dangerouslySetInnerHTML={{ __html: eventDetails[0].summary }}
                ></div>
              </div>
              <div class="col-md-4">
                <img
                  src={window.location.origin + "/images/location-pointer.png"}
                  alt=""
                  class="d-md-block d-none"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section class="full_width Home_banner">
          <div class="container">
            <div className="row">
              <div className="col">
                {loading && <Loader />}
                {loading && (
                  <div>
                    <h1>
                      <Skeleton />
                    </h1>
                    <p>
                      <Skeleton count={5} />
                    </p>
                    <p>
                      <Skeleton count={5} />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
