import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function ThankYou() {
  return (
    <>
      <Helmet>
        <title>Thank You | SourceDesk</title>
      </Helmet>
      <section
        className="full_width fix_padding section-thank-you"
        style={{ background: "url(images/thank-u-bg.jpg)" }}
      >
        <div className="container">
          <div className="text_controller text_controller_center">
            <h1>
              <span>Thank</span> Y<img src="images/thank-u-o.png" alt="" />u
            </h1>
          </div>
          <div className="text-center">
            <p className="mb-1">
              Thank you for registering with us! We're excited to share our
              expertise with you. If you have any questions or need assistance,
              feel free to reach out to us at{" "}
              <a href="mailto:sales@sourcedesk.io">sales@sourcedesk.io</a>.
              We're here to help!
            </p>
            <p>We look forward to growing together with you.</p>
            <img src="images/thank-u-checkmark.jpg" alt="" />
            <div className="cmn_btn_grp w-100 justify-content-center">
              <NavLink to="/" className="cmn_btn_fill">
                Start the journey
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
