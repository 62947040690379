import { Helmet } from "react-helmet";
import InnerBanner from "../Components/InnerBanner";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function CurrentOpenings() {
  const { pathname } = useLocation();
  const currentUrl = window.location.href;
  const navigate = useNavigate();

  const get_all_openings = "https://backend.sourcedesk.io/api/v1/get-all-openings";
  const [alljobs, setallJobs] = useState([]);
  const [alljoblocation, setallJoblocation] = useState([]);

  useEffect(() => {
    getallOpenings();
  }, []);

  const getallOpenings = () => {
    axios
      .get(get_all_openings)
      .then((res) => {
        setallJobs(res.data.all_job_list);
        setallJoblocation(res.data.job_list_location_wise);
      })
      .catch((err) => {
        console.log(err);
      });
  };

 
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>Find all Current Openings - Sourcedesk</title>
        <meta name="description" content="Find all Current Openings" />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Find all Current Openings - Sourcedesk"
        />
        <meta property="og:description" content="Find all Current Openings" />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Find all Current Openings - Sourcedesk"
        />
        <meta name="twitter:description" content="Find all Current Openings" />
      </Helmet>
      <InnerBanner
        InnBanner="images/current-openings-bg.jpg"
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg="images/current-openings-right.png"
        BnrLeftImg=""
        TitleInn={["Find all ", <br />, <span>Current Openings</span>]}
        ParaInn=""
        priCTALink={""}
        priCTATitle={""}
        secCTALink={""}
        secCTATitle={""}
      />
      <section className="full_width fix_padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text_controller text_controller_center">
                <h2>
                  Current <span>Openings</span>
                </h2>
                <hr />
              </div>
            </div>
          </div>

          <div className="row">
            {alljobs.length > 0 ? (
              <div className="d-none d-lg-block accordion_def">
                <Openings allJobs={alljobs} allJobLocation={alljoblocation} />
              </div>
            ) : (
              <div></div>
            )}

            {alljobs.length > 0 ? (
              <div className="d-block d-lg-none accordion_def">
                <OpeningsMob
                  allJobs={alljobs}
                  allJobLocation={alljoblocation}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>
      <section
        className="full_width fix_padding foot_gap"
        style={{
          background:
            "url(images/life-at-sdg-cta-bg-1.jpg) 50% 50% no-repeat rgb(27, 33, 45)",
        }}
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <div className="">
              <p className="fs-2 text-center text-sm-end pe-sm-3 pe-lg-4 border-right text-white">
                <span>READY TO</span>
                <br /> LEARN More?
              </p>
            </div>
            <p className="ps-sm-3 ps-lg-4 me-sm-5 text-white text-center text-sm-start">
              Talk to an expert from Sourcedesk team.
            </p>
            <NavLink
              className="cmn_btn_fill text-nowrap ms-sm-5 cmn_btn_fill_white me-0"
              to="/contact-us"
            >
              Begin Now
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

const Openings = ({ allJobs, allJobLocation }) => {
  if (allJobs.length) {
    return (
      <Tabs
        defaultActiveKey="all"
        id="fill-tab-example"
        className="mb-3 justify-content-center"
        fill
      >
        <Tab eventKey="all" title="All Jobs">
          {allJobs.map((item, i) => (
            <div className="d-flex job-listing">
              <div className="job-icon">
                <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
              </div>

              <div className="title me-2">
                <p className="job-title">{item.job_title}</p>
                <p
                  style={{ color: "#a2a2a2" }}
                  className="text-nowrap experience"
                >
                  <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                  {item.job_required_experience}
                </p>
              </div>

              <div className="info d-flex align-items-center ms-auto">
                <div className="place text-nowrap">
                  {" "}
                  <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                  {item.job_location}{" "}
                </div>
                <div className="posted-on text-nowrap">
                  {" "}
                  <i
                    className="fa fa-calendar me-2"
                    aria-hidden="true"
                  ></i>{" "}
                  {item.job_opening_date}{" "}
                </div>
                <NavLink
                  to={item.job_details_url}
                  className="cmn_btn_fill m-0 d-inline-block text-nowrap"
                  target="_blank"
                >
                  View Details
                </NavLink>
              </div>
            </div>
          ))}

          {/* <div className="d-flex justify-content-center">
          <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
            Load More
          </NavLink>
        </div> */}
        </Tab>
        {allJobLocation.map((res, i) => (
          <Tab eventKey={res.location_slug} title={res.location_label_title}>
            {res.location_joblist.map((itemm, i) => (
              <div className="d-flex job-listing">
                <div className="job-icon">
                  <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                </div>

                <div className="title me-2">
                  <p className="job-title">{itemm.job_title}</p>
                  <p
                    style={{ color: "#a2a2a2" }}
                    className="text-nowrap experience"
                  >
                    <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                    {itemm.job_required_experience}
                  </p>
                </div>

                <div className="info d-flex align-items-center ms-auto">
                  <div className="place text-nowrap">
                    {" "}
                    <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                    {itemm.job_location}{" "}
                  </div>
                  <div className="posted-on text-nowrap">
                    {" "}
                    <i
                      className="fa fa-calendar me-2"
                      aria-hidden="true"
                    ></i>{" "}
                    {itemm.job_opening_date}{" "}
                  </div>
                  <NavLink
                    to={itemm.job_details_url}
                    className="cmn_btn_fill m-0 d-inline-block text-nowrap"
                    target="_blank"
                  >
                    View Details
                  </NavLink>
                </div>
              </div>
            ))}
          </Tab>
        ))}
      </Tabs>
    );
  }
};

const OpeningsMob = ({ allJobs, allJobLocation }) => {
  if (allJobs.length) {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header as="div">All Jobs</Accordion.Header>
          <Accordion.Body>
            {allJobs.map((item, i) => (
              <NavLink
                className="d-flex job-listing"
                to={item.job_details_url}
                target="_blank"
              >
                <div className="job-icon">
                  <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                </div>
                <div className="title me-2">
                  <p className="job-title">{item.job_title}</p>
                  <p style={{ color: "#a2a2a2" }} className="experience">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                    {item.job_required_experience}{" "}
                    <span className="mx-1">|</span>{" "}
                    <i className="fa fa-map-marker me-2" aria-hidden="true"></i>{" "}
                    {item.job_location} <span className="mx-1">|</span>{" "}
                    <i className="fa fa-calendar me-2" aria-hidden="true"></i>{" "}
                    {item.job_opening_date}
                  </p>
                </div>
              </NavLink>
            ))}

            {/* <div className="d-flex justify-content-center">
            <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
              Load More
            </NavLink>
          </div> */}
          </Accordion.Body>
        </Accordion.Item>

        {allJobLocation.map((res, i) => (
          <Accordion.Item eventKey={res.location_id}>
            <Accordion.Header as="div">
              {res.location_label_title}
            </Accordion.Header>
            <Accordion.Body>
              {res.location_joblist.map((itemm, i) => (
                <NavLink
                  className="d-flex job-listing"
                  to={itemm.job_details_url}
                  target="_blank"
                >
                  <div className="job-icon">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                  </div>
                  <div className="title me-2">
                    <p className="job-title">{itemm.job_title}</p>
                    <p style={{ color: "#a2a2a2" }} className="experience">
                      <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                      {itemm.job_required_experience}{" "}
                      <span className="mx-1">|</span>{" "}
                      <i
                        className="fa fa-map-marker me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      {itemm.job_location} <span className="mx-1">|</span>{" "}
                      <i className="fa fa-calendar me-2" aria-hidden="true"></i>{" "}
                      {itemm.job_opening_date}
                    </p>
                  </div>
                </NavLink>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  }
};
