import { Helmet } from "react-helmet";
// import BlogsSlider from "../Components/BlogsSlider";
import InnerBanner from "../Components/InnerBanner";
import OurTeamTabs from "../Components/OurTeamTabs";
import TrustedSlider from "../Components/TrustedSlider";
import BlogsSliderpopular from "../Components/BlogsSliderpopular";
import { useLocation } from "react-router-dom";

export default function OurTeam() {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;
  return (
    <>
      <Helmet>
        <title>Pre-Vetted Remote Individuals - Meet Our Team</title>
        <meta
          name="description"
          content="Meet our pre-vetted remote individuals at Sourcedesk. Discover a team of skilled professionals ready to elevate your projects. Your success, our expertise."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Pre-Vetted Remote Individuals - Meet Our Team"
        />
        <meta
          property="og:description"
          content="Meet our pre-vetted remote individuals at Sourcedesk. Discover a team of skilled professionals ready to elevate your projects. Your success, our expertise."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Pre-Vetted Remote Individuals - Meet Our Team"
        />
        <meta
          name="twitter:description"
          content="Meet our pre-vetted remote individuals at Sourcedesk. Discover a team of skilled professionals ready to elevate your projects. Your success, our expertise."
        />
        {/* <script type="application/ld+json">
          {`
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "@id": "https://www.sourcedesk.io/#breadcrumb",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Why Sourcedesk",
      "item": "javascript:void(0)"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Our Team",
      "item": "https://www.sourcedesk.io/our-team"
    }
  ]
`}
        </script>
        <script type="application/ld+json">
          {`
  "@context": "http://schema.org",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "Our Team",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    }
  },
  "author": {
    "@type": "Person",
    "name": "SourceDesk"
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4.5",
    "bestRating": "5",
    "worstRating": "1"
  },
  "reviewBody": "Review Body"
`}
        </script> */}
      </Helmet>
      <InnerBanner
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/our_team_banner_grphcs.png"
        InnBanner="images/our_team_bg.png"
        TitleInn="Unleashing Brilliance, Powering Growth"
        ParaInn="Meet the Visionaries Behind Your Business's Phenomenal Success"
        priCTALink="/schedule-a-call"
        priCTATitle="Contact Us for Professional Guidance"
        secCTALink=""
        secCTATitle=""
        BnrLeftImg=""
        BnrLeftImgAlt=""
      />
      <TrustedSlider defaultTitle="Trusted By" />
      <section className="full_width verses_sec fix_padding backing_SDG">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              {" "}
              <span>Introducing Sourcedesk </span> <br />
              Empowering Tech Businesses
            </h2>
            <hr />
            <p>
              At Sourcedesk, we understand the challenges of hiring skilled tech
              professionals in the face of budget constraints and talent
              shortages, especially in countries like the USA and Canada. We aim
              to revolutionize hiring through AI-driven top talent services. Our
              platform addresses complexities and cost issues in recruitment,
              onboarding, administration, and benefits management. With a global
              talent pool of pre-vetted professionals, we streamline hiring,
              ensuring technical proficiency and problem-solving skills.
              Rigorous background checks provide added security. Our scalable
              solutions help build high-performing, customized teams, reducing
              costs and unlocking growth potential for your business.
            </p>
          </div>
        </div>
      </section>
      <OurTeamTabs />
      {/* <BlogsSlider heading="Leadership Thoughts" /> */}
      <BlogsSliderpopular heading="Leadership Thoughts" />
    </>
  );
}
