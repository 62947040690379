import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function CustomerReviewSlider() {
  const get_testimonial_list =
    "https://backend.sourcedesk.io/api/v1/get-testimonial-list";
  const [testimonialList, settestimonialList] = useState([]);

  useEffect(() => {
    gettestimoniallist();
  }, []);

  const gettestimoniallist = () => {
    axios
      .get(get_testimonial_list)
      .then((res) => {
        settestimonialList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const settings = {
    dots: true,
    variableWidth: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#c2c2c2",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (testimonialList.length > 0) {
    return (
      <div
        className="slider-container customer-review-slider cus_rev_testi"
        id="cmn_slider_btn"
      >
        <Slider {...settings} className="">
          {testimonialList.map((item, i) => {
            return (
              <div>
                <div className="testimony_main">
                  <div className="testimony_client">
                    <img
                      src={
                        window.location.origin +
                        "/images/customer_review_avatar.png"
                      }
                      alt="Img"
                    />
                  </div>

                  <div className="testimony_sub">
                    {/* <img
                  src={window.location.origin + '/images/customer_review_rating.png'}
                  alt="Img"
                /> */}
                    <div>
                      {" "}
                      {Array.from({ length: item.ratings }, (_, i) => (
                        <i
                          class="fa fa-star"
                          aria-hidden="true"
                          style={{
                            color: "#f25d23",
                            fontSize: "1.75rem",
                            marginRight: "4px",
                          }}
                        ></i>
                      ))}
                    </div>
                    <p>{item.testimonial_content}</p>
                    <h3>{item.full_name}</h3>
                    <h4>{item.designation}</h4>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
export default function CustomerReview() {
  const get_video_details = "https://backend.sourcedesk.io/api/v1/get-video-data";
  const [vdoDetails, setvdoDetails] = useState([]);

  const getVdodetails = () => {
    axios
      .get(get_video_details, {
        params: {
          page_id: 3,
        },
      })
      .then(function (response) {
        
        setvdoDetails(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getVdodetails();
  }, []);
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;
  return (
    <>
      <Helmet>
        <title>
          Hear from Our Clients: Sourcedesk Customer Reviews and Testimonials
        </title>
        <meta
          name="description"
          content="Sourcedesk's strength unfolds in client reviews. Explore testimonials revealing satisfaction and success. Elevate your confidence in our exceptional services."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Hear from Our Clients: Sourcedesk Customer Reviews and Testimonials"
        />
        <meta
          property="og:description"
          content="Sourcedesk's strength unfolds in client reviews. Explore testimonials revealing satisfaction and success. Elevate your confidence in our exceptional services."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Hear from Our Clients: Sourcedesk Customer Reviews and Testimonials"
        />
        <meta
          name="twitter:description"
          content="Sourcedesk's strength unfolds in client reviews. Explore testimonials revealing satisfaction and success. Elevate your confidence in our exceptional services."
        />
      </Helmet>
      <InnerBanner
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/customer_review_right.png"
        InnBanner="images/customer_review_banner.png"
        TitleInn="Insights from Our Loyal Client Base"
        ParaInn="Review the structured evaluations and appreciative comments from those who have utilized our specialized services."
        priCTALink="/schedule-a-call"
        priCTATitle="Connect with Our Expert Professionals Now"
        secCTALink=""
        secCTATitle=""
        BnrLeftImg=""
        BnrLeftImgAlt=""
      />
      {/* {vdoDetails.length>0 ? (
       <section className="full_width leadership_thaught cus_rev_slider fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2 dangerouslySetInnerHTML={{__html: vdoDetails[0].heading}}></h2>
            <hr />
          </div>
          <div className="devloper_slider slider" id="cmn_slider_btn">
            <div>
              <div
                className="vettng_video"
                style={{
                  background:
                    "url("+vdoDetails[0].video_thumbnail+")",
                  backgroundPosition: "50% 50%; background-size: cover",
                }}
              >
                <a href={vdoDetails[0].video_url} target="_blank" rel="noreferrer">
                  <div className="vdo_btn">
                    <img
                      src={window.location.origin + '/images/vdo_triangle.png'}
                      alt="Icon"
                    />
                  </div>
                </a>
                <h2>{vdoDetails[0].video_title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      ): (
        <section></section>
    )}

      <section className="full_width vettng_video_black pricing_pgbk customer_review_pgbk">
        <div className="container">
          <h2>
            Ready to Build a High-Caliber Tech Team? <br /> Start Now
          </h2>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink
              to="/how-it-works"
              className="cmn_btn_stroke"
            >
              Initial Overview: How It Functions{" "}
            </NavLink>
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">
              {" "}
              Begin Now{" "}
            </NavLink>
          </div>
        </div>
      </section> */}

      <section
        className="full_width fix_padding"
        style={{ background: "#f9fcfc", overflowX: "hidden" }}
      >
        <div className="container">
          <div className="text_controller">
            <h2>Customer Feedback and Reviews</h2>
            <hr />
          </div>
        </div>
        <CustomerReviewSlider />
      </section>

      <div className="full_width trusted_by cr_slider fix_padding">
        <TrustedSlider
          defaultTitle="Discover Clients Who Trust Our Services"
          ctaLink="/hire-development-team"
          ctaTitle="Start Working with Us"
        />
      </div>

      <section className="full_width vettng_video_black foot_gap sdg_advntg_video_black  ">
        <div className="container text_controller text_controller_center">
          <h2>Explore Exceptional Talent</h2>
          <div className="cmn_btn_grp ">
            <NavLink to="/pricing" className="cmn_btn_fill">
              View Our Pricing Rate Card
            </NavLink>
            <NavLink to="/schedule-a-call" className="cmn_btn_stroke">
              {" "}
              Connect with Leading Industry Experts{" "}
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
