import React from "react";
import ComingSoon from "../Components/ComingSoon";

export default function Portfolio() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}
