import React, { useEffect, useState } from 'react';
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from 'react-router-dom';

function TalentVTab() {
    const get_technology_data = "https://backend.sourcedesk.io/api/v1/get-technology-list";
    const [tabsData, settabsData] = useState([]);
    useEffect(() => {
        gettabsData();
      }, []);

      const gettabsData = () => {
        axios
          .get(get_technology_data)
          .then((res) => {
            
            settabsData(res.data);
  
            
          })
          .catch((err) => {
            console.log(err);
          });
      };
if(tabsData.length>0){


    return (
        <>
            <section className="full_width talent_sec fix_padding border_top">
                <div className="container">
                    <div className="talent_sec_top mb-5">
                        <div className="tlnt_sec_top_left text_controller">
                            <h2>Unleash the Power of <span>Sourcedesk's</span> IT Talent Network</h2>  
                        </div>

                        <div className="tlnt_sec_top_right">
                            <p>Discover 50,000+ Pre-Vetted Talents for Your Next Project</p>                
                            <p>Hire a Single Developer or Build a Team with Ease</p>
                        </div>
                    </div>

                    <div className='d-none d-lg-block'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            {tabsData.map((tab,i)=>{
                                                return(
                                                    <Nav.Link className='desk_tb_title' eventKey={tab.eventKey} key={i}>{tab.tabName}</Nav.Link> 
                                                );
                                            })}
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Col sm={9}>
                                    <Tab.Content className='desk_tb_content'>
                                        {tabsData.map((tab,i)=>{
                                            return(
                                                <Tab.Pane eventKey={tab.eventKey} key={i}>
                                                    <ul>
                                                        {tab.webdevImg.map((tab,i) => <li key={i}><NavLink to={tab.page_url}><img src={tab.src} alt={tab.alt} /></NavLink></li>)}
                                                    </ul>
                                                </Tab.Pane>
                                            );
                                        })}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>

                    <div className='d-lg-none v_accordion'>
                        {/* defaultActiveKey={tabsData[0].eventKey} */}
                        <Accordion>
                            {tabsData.map((tab,i)=>{
                                return(
                                    <Accordion.Item eventKey={tab.eventKey} key={i}>
                                        <Accordion.Header>{tab.tabName}</Accordion.Header> 
                                        <Accordion.Body className='desk_tb_content'>
                                            <ul>
                                                {tab.webdevImg.map((tab,i) => <li key={i}><NavLink to='/' onClick={e=>e.preventDefault()}><img src={tab.src} alt={tab.alt} /></NavLink></li>)}
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    )
}
}

export default TalentVTab;